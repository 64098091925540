$(function() {
  var stContainerC = (function() {
    /**
     * イベント系の記事一覧の2ページ以降の最近の記事を表示しない
     */
    var remove = (function() {
      // URLからファイル名だけを取得する
      function basename(path) {
        return path.replace(/\\/g,'/').replace( /.*\//, '' );
      }
      // URLからディレクトリだけを取得する
      function dirname(path) {
        return path.replace(/\\/g,'/').replace(/\/[^\/]*$/, '') + '/';;
      }

      var locationPath = location.pathname;
      var dirName = dirname(location.pathname);
      var fileName = basename(location.href);
      var isMatchDir = false;
      var isSecondPage = false;
      // MT管理でページ送りが表示されるディレクトリを渡します。
      var path = [
        "/events/",
        "/events/event/",
        "/events/eventreport/",
        "/events/business/",
        "/events/technology/",
        "/events/science/",
        "/events/artculture/",
        "/events/seminar/",
        "/events/symposium/",
        "/events/pitch/",
        "/events/workshop/",
        "/events/exhibition/",
        "/events/meetup/",
        "/events/student/",
        "/events/krp/",
        "/sks/start/events/",
        "/sks/municipality/events/",
        "/sangaku/onecoin/events/",
        "/sangaku/c_table/events/",
        "/sangaku/electronics/events/",
        "/sangaku/bio/events/",
        "/_events/",
        "/_events/event/",
        "/_events/eventreport/",
        "/_events/business/",
        "/_events/technology/",
        "/_events/science/",
        "/_events/artculture/",
        "/_events/seminar/",
        "/_events/symposium/",
        "/_events/pitch/",
        "/_events/workshop/",
        "/_events/exhibition/",
        "/_events/meetup/",
        "/_events/student/",
        "/_events/krp/",
        "/_sks/start/events/",
        "/_sks/municipality/events/",
        "/_sangaku/onecoin/events/",
        "/_sangaku/c_table/events/",
        "/_sangaku/electronics/events/",
        "/_sangaku/bio/events/"
      ];

      // 特定のディレクトリと一致したらtrueを返す。
      for(var i = 0; i < path.length; i++) {
        if(path[i] === dirName) {
          isMatchDir = true;
          break;
        }
      }

      // 最後が/か/index.html以外であればtrueを返す。
      if(fileName !== '' && fileName !== 'index.html') {
        isSecondPage = true;
      }

      // 条件に合致する場合は要素を削除する。
      if(isMatchDir && isSecondPage) {
        $('.st-ContainerD').remove();
        $('.st2-PageOnly').remove();
        $('.st-ContainerA').css('padding-bottom','0');
      }
    })();
  })();

}());
