$(function() {

  /**
   * Googleマップで出発地点と到着地点のルートを表示します。
   * gmaps.jsを使用しています。
   * `fromKyotoStation`をコピーして追加してください。
   */
  var swMap = (function() {
    var isSp = '';
    function checkBreakpoint() {
      var windowWidth = $(window).innerWidth();
      if(windowWidth > 980) {
        isSp = false;
      } else {
        isSp = true;
      }
    }
    checkBreakpoint();
    $(window).on('resize', function() {
      checkBreakpoint();
    });

    var options = {
      zoom: 15,
      travelMode: 'driving', // モード(driving / bicycling / walking）
      strokeColor: '#be3134', // ルートの色
      strokeOpacity: 0.8, // ルートの透明度
      strokeWeight: 4 // ルート線の太さ
    };

    var fromKyotoStation = (function() {
      if($('#mapFromKyotoStation').length) {
        // 出発地点の緯度と経度
        var from = {
          lat: '34.985718',
          lng: '135.758516'
        };
        // 到着地点の緯度と経度
        var to = {
          lat: '34.995079',
          lng: '135.740288'
        };
        if(!isSp) {
          var center = {
            lat: '34.9903985', // (from.lat + to.lat) / 2
            lng: '135.749402' // (from.lng + to.lng) / 2
          }
        } else {
          var center = {
            lat: from.lat,
            lng: from.lng
          }
        }
        var map = new GMaps({
          lat: center.lat,
          lng: center.lng,
          div: "#mapFromKyotoStation", //id属性値
          zoom: options.zoom
        });
        map.drawRoute({
            origin: [from.lat, from.lng],
            destination: [to.lat, to.lng],
            travelMode: options.travelMode,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight
          });
        map.addMarker({
          lat: from.lat,
          lng: from.lng,
          title: "出発地点：JR京都駅",
          infoWindow: {
            content: "<p>出発地点：JR京都駅</p>"
          }
        });
        map.addMarker({
          lat: to.lat,
          lng: to.lng,
          title: "到着地点：京都リサーチパーク",
          infoWindow: {
            content: "<p>到着地点：京都リサーチパーク</p>"
          }
        });
      }
    }());

    var fromHankyuSaiin = (function() {
      if($('#mapFromHankyuSaiin').length) {

        // 出発地点の緯度と経度
        var from = {
          lat: '35.003453',
          lng: '135.731690'
        };
        // 到着地点の緯度と経度
        var to = {
          lat: '34.995079',
          lng: '135.740288'
        };
        if(!isSp) {
          var center = {
            lat: '34.999266', // (from.lat + to.lat) / 2
            lng: '135.735989', // (from.lng + to.lng) / 2
          }
        } else {
          var center = {
            lat: from.lat,
            lng: from.lng
          }
        }
        var map = new GMaps({
          lat: center.lat,
          lng: center.lng,
          div: "#mapFromHankyuSaiin", //id属性値
          zoom: options.zoom
        });
        map.drawRoute({
            origin: [from.lat, from.lng],
            destination: [to.lat, to.lng],
            travelMode: options.travelMode,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight
          });
        map.addMarker({
          lat: from.lat,
          lng: from.lng,
          title: "出発地点：阪急西院駅",
          infoWindow: {
            content: "<p>出発地点：阪急西院駅</p>"
          }
        });
        map.addMarker({
          lat: to.lat,
          lng: to.lng,
          title: "到着地点：京都リサーチパーク",
          infoWindow: {
            content: "<p>到着地点：京都リサーチパーク</p>"
          }
        });
      }
    }());

    var mapFromJrTanba = (function() {
      if($('#mapFromJrTanba').length) {

        // 出発地点の緯度と経度
        var from = {
          lat: '34.995701',
          lng: '135.742314'
        };
        // 到着地点の緯度と経度
        var to = {
          lat: '34.995079',
          lng: '135.740288'
        };
        if(!isSp) {
          var center = {
            lat: '34.99539', // (from.lat + to.lat) / 2
            lng: '135.741301', // (from.lng + to.lng) / 2
          }
        } else {
          var center = {
            lat: from.lat,
            lng: from.lng
          }
        }
        var map = new GMaps({
          lat: center.lat,
          lng: center.lng,
          div: "#mapFromJrTanba", //id属性値
          zoom: 17
        });
        map.drawRoute({
            origin: [from.lat, from.lng],
            destination: [to.lat, to.lng],
            travelMode: options.travelMode,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight
          });
        map.addMarker({
          lat: from.lat,
          lng: from.lng,
          title: "出発地点：阪急西院駅",
          infoWindow: {
            content: "<p>出発地点：阪急西院駅</p>"
          }
        });
        map.addMarker({
          lat: to.lat,
          lng: to.lng,
          title: "到着地点：京都リサーチパーク",
          infoWindow: {
            content: "<p>到着地点：京都リサーチパーク</p>"
          }
        });
      }
    }());

    var mapFromJrTanbaToGoconc = (function() {
      if($('#mapFromJrTanbaToGoconc').length) {

        // 出発地点の緯度と経度
        var from = {
          lat: '34.995701',
          lng: '135.742314'
        };
        // 到着地点の緯度と経度
        var to = {
          lat: '34.99584300589756',
          lng: '135.738183304907'
        };
        if(!isSp) {
          var center = {
            lat: '34.99539', // (from.lat + to.lat) / 2
            lng: '135.741301', // (from.lng + to.lng) / 2
          }
        } else {
          var center = {
            lat: from.lat,
            lng: from.lng
          }
        }
        var map = new GMaps({
          lat: center.lat,
          lng: center.lng,
          div: "#mapFromJrTanbaToGoconc", //id属性値
          zoom: 17
        });
        map.drawRoute({
            origin: [from.lat, from.lng],
            destination: [to.lat, to.lng],
            travelMode: options.travelMode,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight
          });
        map.addMarker({
          lat: from.lat,
          lng: from.lng,
          title: "出発地点：阪急西院駅",
          infoWindow: {
            content: "<p>出発地点：阪急西院駅</p>"
          }
        });
        map.addMarker({
          lat: to.lat,
          lng: to.lng,
          title: "到着地点：京都リサーチパーク10号館1F",
          infoWindow: {
            content: "<p>到着地点：京都リサーチパーク10号館1F</p>"
          }
        });
      }
    }());

    var mapFromHankyuOmiya = (function() {
      if($('#mapFromHankyuOmiya').length) {

        // 出発地点の緯度と経度
        var from = {
          lat: '35.003602',
          lng: '135.748771'
        };
        // 到着地点の緯度と経度
        var to = {
          lat: '34.995079',
          lng: '135.740288'
        };
        if(!isSp) {
          var center = {
            lat: '34.9996515', // (from.lat + to.lat) / 2
            lng: '135.7455425', // (from.lng + to.lng) / 2
          }
        } else {
          var center = {
            lat: from.lat,
            lng: from.lng
          }
        }
        var map = new GMaps({
          lat: center.lat,
          lng: center.lng,
          div: "#mapFromHankyuOmiya", //id属性値
          zoom: options.zoom
        });
        map.drawRoute({
            origin: [from.lat, from.lng],
            destination: [to.lat, to.lng],
            travelMode: options.travelMode,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight
          });
        map.addMarker({
          lat: from.lat,
          lng: from.lng,
          title: "出発地点：阪急西院駅",
          infoWindow: {
            content: "<p>出発地点：阪急西院駅</p>"
          }
        });
        map.addMarker({
          lat: to.lat,
          lng: to.lng,
          title: "到着地点：京都リサーチパーク",
          infoWindow: {
            content: "<p>到着地点：京都リサーチパーク</p>"
          }
        });
      }
    }());

    var mapFromsubwayGojyo = (function() {
      if($('#mapFromsubwayGojyo').length) {

        // 出発地点の緯度と経度
        var from = {
          lat: '34.996577',
          lng: '135.760124'
        };
        // 到着地点の緯度と経度
        var to = {
          lat: '34.995079',
          lng: '135.740288'
        };
        if(!isSp) {
          var center = {
            lat: '34.996139', // (from.lat + to.lat) / 2
            lng: '135.751219', // (from.lng + to.lng) / 2
          }
        } else {
          var center = {
            lat: from.lat,
            lng: from.lng
          }
        }
        var map = new GMaps({
          lat: center.lat,
          lng: center.lng,
          div: "#mapFromsubwayGojyo", //id属性値
          zoom: options.zoom
        });
        map.drawRoute({
            origin: [from.lat, from.lng],
            destination: [to.lat, to.lng],
            travelMode: options.travelMode,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight
          });
        map.addMarker({
          lat: from.lat,
          lng: from.lng,
          title: "出発地点：阪急西院駅",
          infoWindow: {
            content: "<p>出発地点：阪急西院駅</p>"
          }
        });
        map.addMarker({
          lat: to.lat,
          lng: to.lng,
          title: "到着地点：京都リサーチパーク",
          infoWindow: {
            content: "<p>到着地点：京都リサーチパーク</p>"
          }
        });
      }
    }());

    var mapFromKeihanKiyomizugojyo = (function() {
      if($('#mapFromKeihanKiyomizugojyo').length) {

        // 出発地点の緯度と経度
        var from = {
          lat: '34.996225',
          lng: '135.768500'
        };
        // 到着地点の緯度と経度
        var to = {
          lat: '34.995079',
          lng: '135.740288'
        };
        if(!isSp) {
          var center = {
            lat: '34.995963', // (from.lat + to.lat) / 2
            lng: '135.755407', // (from.lng + to.lng) / 2
          }
        } else {
          var center = {
            lat: from.lat,
            lng: from.lng
          }
        }
        var map = new GMaps({
          lat: center.lat,
          lng: center.lng,
          div: "#mapFromKeihanKiyomizugojyo", //id属性値
          zoom: 14
        });
        map.drawRoute({
            origin: [from.lat, from.lng],
            destination: [to.lat, to.lng],
            travelMode: options.travelMode,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight
          });
        map.addMarker({
          lat: from.lat,
          lng: from.lng,
          title: "出発地点：阪急西院駅",
          infoWindow: {
            content: "<p>出発地点：阪急西院駅</p>"
          }
        });
        map.addMarker({
          lat: to.lat,
          lng: to.lng,
          title: "到着地点：京都リサーチパーク",
          infoWindow: {
            content: "<p>到着地点：京都リサーチパーク</p>"
          }
        });
      }
    }());

  }());

}());
