$(function() {

  function stHeaderSubNavSearchButton() {
    $('.st-HeaderSubNav_SearchButton').on('click', function(e) {
      var $button = $('#st-HeaderSubNav_SearchButton');
      var $search = $('#st-HeaderSubNav_Search');
      var $input = $('#st-HeaderSubNav_Search .gsc-input');

      function open() {
        $button.attr('aria-expanded', 'true');
        $search.attr('aria-hidden', 'false');
        $input.focus();
      }
      function close() {
        $button.attr('aria-expanded', 'false').focus();
        $search.attr('aria-hidden', 'true');
      }
      if($button.attr('aria-expanded') === 'false') {
        open();
      } else {
        close();
      }
      e.stopPropagation();
    });
  }
  stHeaderSubNavSearchButton();

}());
