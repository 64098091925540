$(function() {

  if($('.serviceoffice-StreetView').length) {

    /**
     * リンク先を取得してストリートビューに反映する。
     */
    var $iframe = $('.serviceoffice-StreetView_Iframe iframe');
    $('.serviceoffice-StreetView_Links area').on('click', function(e) {
      e.preventDefault();
      var href = $(this).attr('href');
      $iframe.attr('src', href);
    });

    /**
     * クリッカブルマップをレスポンシブ対応にする。
     */
    $('.serviceoffice-StreetView_Links img[usemap]').rwdImageMaps();

  }

}());
