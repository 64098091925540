$(function () {

  /**
   * 各詳細ページにイベントやニュースの最新３件表示する
   * htmlのjsonから取得＋表示させる
   */

  if ($('.st-ContainerE').length) {
    var result = '';  //出力用オブジェクト
    function getBtnUrl() {
      var pathArray = { //jsonからボタンのurlを取得しないパターン
        'onecoin': '/sangaku/onecoin/events/',
        'c_table': '/sangaku/c_table/events/'
      };
      var dirArray = $(location).attr('pathname').split('/');
      var dir = dirArray[dirArray.length - 3]; //2つ上のディレクトリを取得
      var path = ($.inArray(dir, dirArray)) ? pathArray[dir] : '';  //dirArrayに設定されているか確認
      return (path && path.length) ?  //pathが取得できていれば返す
        $(location).attr('protocol') + '\/\/' + $(location).attr('host') + path :
        '';
    }

    function escapeHtml(str) {  //XSS対策
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#39;');
      return str;
    }

    try {
      var jsonPath = $('.st-ContainerE').attr('data-json-file-path'); //htmlに埋め込まれたjsonのファイルパスを取得
      if (jsonPath && jsonPath.length > 0) { //パスが取得できたら処理する
        $.getJSON(jsonPath)
          .done(function (json) {

            var entryJson = json['marker'];

            result += '<div class="st-Wrapper">' +  //見出し
              '<h2 class="sw-Heading2B sw-Heading2B-dark st-Heading2">' +
              escapeHtml(entryJson[0]['areatitle']) +
              '</h2>';

            if (entryJson && entryJson.length > 0) {  //json内にデータが1件以上あったとき
              result += '<ul class="st-Grids st-Grids-col3Lg">';

              for (var i = 0; i < 3; i++) { //jsonデータの3件目まで処理を行う
                if(!entryJson[i]) {continue;}
                for (var elem in entryJson[i]) { //無害化しておく
                  entryJson[i][elem] = escapeHtml(entryJson[i][elem]);
                }

                result += '<li class="st-Grids_Item">';

                if (entryJson[i]['outlinksw'] && entryJson[i]['outlinksw'] === '1') { //outlinksw=1のときリンクを別タブで起動する
                  result += '<a class="sw-CardNavA" target=”_blank” ';
                } else {
                  result += '<a class="sw-CardNavA" ';
                }
                result += 'href="' + entryJson[i]['event_link'] + '">';

                result += '<div class="sw-CardNavA_Head">' +
                  '<img class="sw-CardNavA_Image" src="' +
                  entryJson[i]['event_img'] +
                  '" alt="">' +
                  '<div class="sw-CardNavA_Details">' +
                  '<div class="sw-CardNavA_Labels">';

                if (!entryJson[i]['start_date'] || entryJson[i]['start_date'].length < 1) { //start_dateが登録されていないときは下にentry_dateを表示
                  result += '<span class="sw-CardNavA_Label sw-CardNavA_ReportLabel">';
                } else {
                  result += '<span class="sw-CardNavA_Label">';
                }

                result += entryJson[i]['category_name'] +
                  '</span></div>';

                if (entryJson[i]['start_date']) { //start_dateが入っている場合のみ
                  result += '<div class="sw-CardNavA_Date">' +
                    '<div class="sw-CardNavA_DataStart">' +
                    entryJson[i]['start_date'].slice(2, 4) + '\/' + entryJson[i]['start_date'].slice(4, 6) +//開始日
                    '<span class="sw-CardNavA_DataStartWeekday">' +
                    '(' + entryJson[i]['start_week'] + ')' + //(曜日)
                    '</span>' + '</div>';

                  if (entryJson[i]['end_date']) { //start_dateとend_date両方入ってる場合のみ
                    result += '<div class="sw-CardNavA_To" aria-label="から"></div>' +
                      '<div class="sw-CardNavA_DateEnd">' +
                      entryJson[i]['end_date'].slice(2, 4) + '\/' + entryJson[i]['end_date'].slice(4, 6) +//終了日
                      '<span class="sw-CardNavA_DateEndWeekday">' +
                      '(' + entryJson[i]['end_week'] + ')' + //(曜日)
                      '</span>' + '</div>';
                  }
                  result += '</div>';
                }

                result += '</div>';

                if (entryJson[i]['endsw'] && entryJson[i]['endsw'] === '1') { //終了して入れば表示する
                  result += '<div class="sw-CardNavA_EndLabels">' +
                    '<span class="sw-CardNavA_EndLabel">終了</span>' +
                    '</div>';
                }

                result += '</div>' +
                  '<div class="sw-CardNavA_Body">' +
                  '<div class="sw-CardNavA_Text">' +
                  '<span class="sw-CardNavA_Icon" aria-hidden="true"></span>' +
                  entryJson[i]['title'] +
                  '</div>';

                if (!entryJson[i]['start_date'] || entryJson[i]['start_date'].length < 1) { //start_dateが登録されていないときは下にentry_dateを表示
                  result += '<span class="sw-CardNavA_PostDate">' +
                    '20' + entryJson[i]['entry_date'].slice(0, 2) + '\/' + entryJson[i]['entry_date'].slice(2, 4) + '\/' + entryJson[i]['entry_date'].slice(4, 6) +
                    '</span>';
                }

                result += '</div>' + '</a>' + '</li>';
              }
            } else {  //1件もなかった場合何もしない
            }

            result += '</ul>';
            if (!entryJson[0]['btntitle'] === '表示しない') { //ボタンを非表示にする

            var btnUrl = getBtnUrl();
            btnUrl = (btnUrl && btnUrl.length > 0) ? btnUrl : entryJson[0]['btnurl'];

            result += '<div class="st-ContainerE_Foot">' +  //ボタン
              '<a href="' + btnUrl + '" class="sw-Button sw-Button-invert">' +
              entryJson[0]['btntitle'] +
              '<span class="sw-Button_RightArrow" aria-hidden="true"></span>' +
              '</a></div></div>' +
              '</footer>';
            }
          })
          .fail(function () {
            result = ''
          })
          .always(function () { //出力
            var eventsContentFooter = $('.st-ContainerE');
            eventsContentFooter.html(result);
          });
      }
    } catch (e) {
      console.error(e.message)
    } finally {
      result = '';
    }
  }
});
