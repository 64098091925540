$(function() {

  if ($('.sw2-VisualNavC').length) {
    var $vnavcTabs = $('.sw2-VisualNavC [data-vnavc-tab]');
    $vnavcTabs.on('click', function () {
      var $this = $(this);
      $vnavcTabs.not($this.addClass('-is-active')).removeClass('-is-active');

      var vnavcTab = $this.data('vnavc-tab');
      $('.sw2-VisualNavC .sw-VisualNavC_Item').toggle(vnavcTab === 'all').filter(`:has(.-is-${vnavcTab})`).show();

      if ($('.sw2-VisualNavC .sw-VisualNavC_FootItem').length) {
        $('.sw2-VisualNavC .sw-VisualNavC_FootItem').matchHeight({ remove: true }).filter(':visible').matchHeight();
      }
      if ($('.sw2-VisualNavC .sw-VisualNavC_FootImageArea').length) {
        $('.sw2-VisualNavC .sw-VisualNavC_FootImageArea').matchHeight({ remove: true }).filter(':visible').matchHeight();
      }
      if ($('.sw2-VisualNavC .sw-VisualNavC_FootTextArea').length) {
        $('.sw2-VisualNavC .sw-VisualNavC_FootTextArea').matchHeight({ remove: true }).filter(':visible').matchHeight();
      }
      return false;
    });
  }

}());
