$(function () {

  if ($('.news-ListB').length) {

    var result = '';  //出力用オブジェクト

    function escapeHtml(str) {  //XSS対策
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#39;');
      return str;
    }

    try {
      var jsonPath = $('.news-ListB').attr('data-json-file-path');
      if (jsonPath && jsonPath.length > 0) { //パスが取得できたら処理する
        $.getJSON(jsonPath)
          .done(function (json) {

            var entryJson = json['marker'];
            if (entryJson && entryJson.length > 0) {  //1件以上あったとき
              for (var i = 0; i < 3; i++) { //htmlとして出力するように整形
                for (var elem in entryJson[i]) { //無害化しておく
                  entryJson[i][elem] = escapeHtml(entryJson[i][elem]);
                }
                result += '<li class="news-ListB_Item">';

                if (entryJson[i]['outlinksw'] && entryJson[i]['outlinksw'] === '1') { //outlinksw=1のときリンクを別タブで起動する
                  result += '<a class="news-ListB_Link" target=”_blank” ';
                } else {
                  result += '<a class="news-ListB_Link" ';
                }
                result += 'href="' + entryJson[i]['event_link'] + '">';

                result += '<div class="news-ListB_DateItem">' +
                  '<span class="news-ListB_Date">' +
                  '20' + entryJson[i]['entry_date'].slice(0,2) + '\/' + entryJson[i]['entry_date'].slice(2,4) + '\/' + entryJson[i]['entry_date'].slice(4,6) +
                  '</span>' + '</div>' +
                  '<div class="news-ListB_TextItem">' +
                  '<span class="news-ListB_Text">' +
                  entryJson[i]['title'] +
                  '</span>' + '</div>' +
                  '</a>' +
                  '</li>';
              }
            }
          })
          .fail(function () {
            result = ''
          })
          .always(function () {
            //出力
            var eventsContentFooter = $('.news-ListB');
            eventsContentFooter.html(result);
          });
      }
    } catch (e) {
      console.error(e.message)
    } finally {
      result = '';
    }
  }
});
