$(function() {

  $(window).on('scroll resize load', function () {
    if ($('.sw2-LocalNavi').is(':visible') && $('.st2-LocalNav.-is-fixed').length && $('.-invalid-LocalNav').length) {
      $('.st2-Header_Outer').removeClass('-is-fixed');
    }
    if($('.st2-Header').length && (!$('.sw2-LocalNavi').length || $('.sw2-LocalNavi').is(':hidden')) && !$('.-invalid-LocalNav').length) {
      if ($(window).scrollTop() > $('.st2-Header').offset().top + $('.st2-Header').outerHeight()) {
        $('.st2-Header_Outer').addClass("-is-fixed");
      } else {
        $('.st2-Header_Outer').removeClass("-is-fixed");
      }
    }
  });

  /* メガメニュー対応 */
  var activeMegamenu = null;
  $('.st2-Header_NavList li a[data-megamenu], .st2-Header_MegaMenu').on('mouseenter', function () {
    activeMegamenu = $('.st2-Header_NavList li a[data-megamenu].active').data('megamenu');
  }).on('mouseleave', function () {
    activeMegamenu = null;
    setTimeout(function () {
      if (activeMegamenu == null) {
        $('.st2-Header_NavList li a[data-megamenu].active').removeClass('active');
        $('.st2-Header_MegaMenu').finish().fadeOut();
      }
    });
  });
  $('.st2-Header_NavList li a[data-megamenu]').on('mouseenter', function () {
    var $this = $(this);
    var megamenu = $this.data('megamenu');
    if (megamenu != activeMegamenu) {
      activeMegamenu = megamenu;
      $('.st2-Header_NavList li a[data-megamenu].active').removeClass('active');
      $this.addClass('active');
      $('.st2-Header_MegaMenuItemInner').hide();
      $('#' + megamenu).show();
      $('.st2-Header_MegaMenu').finish().removeAttr('style').slideDown();
    }
  });
  $('.st2-Header_NavList li a[data-megamenu]').on('click', function () {
    return false;
  });

  /* サイトマップ対応 */
  if($('.st2-Header_MenuButton').length) {
    $('.st2-Header_MenuButton').on('click',function(){
      if($('body').hasClass("-is-sitemap-open")) {
        $('body').removeClass("-is-sitemap-open").addClass("-is-sitemap-close");
        $('.st2-Header_SiteMap').removeClass("-is-open").addClass("-is-close");
      } else {
        $('body').addClass("-is-sitemap-open").removeClass("-is-sitemap-close");
        $('.st2-Header_SiteMap').addClass("-is-open").removeClass("-is-close");
      }
      return false;
    });
  }
  if($('.st2-Header_SiteMap_Close').length) {
    $('.st2-Header_SiteMap_Close').on('click',function(){
      if($('body').hasClass("-is-sitemap-open")) {
        $('body').removeClass("-is-sitemap-open").addClass("-is-sitemap-close");
        setTimeout(() => {
          $('.st2-Header_SiteMap').removeClass("-is-open").addClass("-is-close");
        }, 400);
      } else {
        $('body').addClass("-is-sitemap-open").removeClass("-is-sitemap-close");
        $('.st2-Header_SiteMap').addClass("-is-open").removeClass("-is-close");
      }
      return false;
    });
  }
  if($('.st2-Header_SiteMap_Header_Search').length) {
    $('.st2-Header_SiteMap_Header_Search_Button').on('click',function(){
      if($('.st2-Header_SiteMap_Header_Search').hasClass("-is-open")) {
        $('.st2-Header_SiteMap_Header_Search').removeClass("-is-open").addClass("-is-close");
        setTimeout(() => {
          $('.st2-Header_SiteMap_Header_Search_Button').removeClass("-is-open").addClass("-is-close");
        }, 300);
      } else {
        $('.st2-Header_SiteMap_Header_Search').addClass("-is-open").removeClass("-is-close");
        $('.st2-Header_SiteMap_Header_Search_Button').addClass("-is-open").removeClass("-is-close");
      }
      return false;
    });
  }

}());
