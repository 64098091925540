$(function() {

  if($('.sw-BoxNavA_Box').length) {
    $('.sw-BoxNavA_Box').matchHeight();
  }

  if($('.sw-BoxNavA_Box_Body').length) {
    $('.sw-BoxNavA_Box_Body').matchHeight();
  }
}());
