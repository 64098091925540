$(function() {

  function stLocalNav3Link() {
    var dir = location.pathname.split("/");
    var url = '/' + dir[1] + '/' + dir[2] + '/' + dir[3] + '/';

    // お問合せリンクだけスタイルを変更する
    if($('.st-LocalNav3_Link').length) {
      $('.st-LocalNav3_Link').each(function() {
        var href = $(this).attr('href');
        var isInquiry = /inquiry\/$/.test(href)
        if(isInquiry) {
          $(this).addClass('st-LocalNav3_Link-inquiry');
          $(this).closest('.st-LocalNav3_Item').addClass('st-LocalNav3_Item-inquiry');
        }
      });
    }

    if($('.st-LocalNav3_Link').length) {
      $('.st-LocalNav3_Link').each(function() {
        var href = $(this).attr('href').split("/");
        if('/' + href[1] + '/' + href[2] + '/' + href[3] + '/' == url ) {
          $(this).closest('.st-LocalNav3_Item').addClass('st-LocalNav3_Item-current');
        }
      });
    }
  }
  stLocalNav3Link();

  if($('.st-LocalNav3_Link').length) {
    $('.st-LocalNav3_Link').matchHeight();
  }

}());
