$(function() {

  function stLocalNav1Link() {
    var dir = location.pathname.split("/");
    var url = '/' + dir[1] + '/' + dir[2] + '/';
    var englishUrl = '/' + dir[1] + '/' + dir[2] + '/' + dir[3] + '/' + dir[4] + '/';
    var isEnglish = false;

    if(dir[1] === 'english' || dir[1] === 'cn' || dir[1] === 'hn') {
      var isEnglish = true;
    }

    // お問合せリンクだけスタイルを変更する
    if($('.st-LocalNav1_Link').length) {
      $('.st-LocalNav1_Link').each(function() {
        var href = $(this).attr('href');
        var isInquiry = /inquiry\/$/.test(href)
        if(isInquiry) {
          $(this).addClass('st-LocalNav1_Link-inquiry');
          $(this).closest('.st-LocalNav1_Item').addClass('st-LocalNav1_Item-inquiry');
        }
      });
    }

    if($('.st-LocalNav1_Link').length && !isEnglish) {
      $('.st-LocalNav1_Link').each(function() {
        var href = $(this).attr('href').split("/");
        if('/' + href[1] + '/' + href[2] + '/' === url ) {
          $(this).closest('.st-LocalNav1_Item').addClass('st-LocalNav1_Item-current');
        }
      });
    } else if($('.st-LocalNav1_Link').length && isEnglish) {
      $('.st-LocalNav1_Link').each(function() {
        var href = $(this).attr('href').split("/");
        if('/' + href[1] + '/' + href[2] + '/'+ href[3] + '/' + href[4] + '/' === englishUrl ) {
          $(this).closest('.st-LocalNav1_Item').addClass('st-LocalNav1_Item-current');
        }
      });
    }

    // /kaigi/room/list.htmlの時は/kaigi/room/price.htmlにカレントを表示させない
    if(location.pathname === '/kaigi/room/list.html' || location.pathname === '/kaigi/room/' || location.pathname === '/kaigi/room/choice.html' || location.pathname === '/kaigi/room/map/') {
      $('.st-LocalNav1_Link[href="/kaigi/room/price.html"]').closest('.st-LocalNav1_Item').removeClass('st-LocalNav2_Link-current');
    }
    // /kaigi/room/price.htmlの時は/kaigi/room/list.htmlにカレントを表示させない
    if(location.pathname === '/kaigi/room/price.html' || location.pathname === '/kaigi/room/price/equipment.html' || location.pathname === '/kaigi/room/price/catering.html' || location.pathname === '/kaigi/room/price/cancel.html') {
      $('.st-LocalNav1_Link[href="/kaigi/room/list.html"]').closest('.st-LocalNav1_Item').removeClass('st-LocalNav1_Item-current');
    }
  }
  stLocalNav1Link();

  if($('.st-LocalNav1_Link').length) {
    $('.st-LocalNav1_Link').matchHeight();
  }

  // ナビ高さ調整 2020/12 add
  if($('.st-LocalNav1_Link_LabPortal').length) {
    $('.st-LocalNav1_Link_LabPortal').matchHeight();
  }
  // ナビ高さ調整 2020/12 add


  function setLocalNav1() {
    if($('.st-LocalNav2').length) {
      $('.st-LocalNav1_Items').addClass('st-LocalNav1_Items-isChild');
    }
  }
  setLocalNav1();


  // ラボポータル(ラボを探す) 現在地表示(第一階層) 2020/12 add
  function stLocalNav1Link_LabPortal() {

    if($('.st-LocalNav1_Link_LabPortal').length) {
      $('.st-LocalNav1_Link_LabPortal').each(function() {
        var href = $(this).attr('href');
        if(href == 'index.html' || href == '/labplus/search/search-map/' || href == '../../' || href == '../' ) {
          $(this).closest('.st-LocalNav1_Item').addClass('st-LocalNav1_Item-current');
        }
      });
    }
  }
  stLocalNav1Link_LabPortal();
  // ラボポータル(ラボを探す) 現在地表示(第一階層) 2020/12 add


}());
