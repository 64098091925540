$(function() {

  if($('.events-Nav1_BodyInner').length) {
    $('.events-Nav1_BodyInner').matchHeight();

    /**
     * 確保している高さよりも低い画像はスタイルを追加してトリミングする。
     */
    $('.events-Nav1_Block').each(function() {
      var parentHeight = $(this).innerHeight();
      var imageHeight = $(this).find($('.events-Nav1_Image')).innerHeight();

      if(parentHeight > imageHeight) {
        $(this).find($('.events-Nav1_Image')).css({
          'max-height': '100%',
          'width': 'auto',
          'max-width': 'none'
        });
      }
    });

  }

}());
