// 二重押下フラグ
var submitFlg = 0;
/*
 * 二重押下チェックがある
 */
function submit(form) {
    if (submitFlg == 1) {
        //　何もしない
    } else {
        submitFlg = 1;
        form.submit();
    }
}

/*
 * すべて選択
 * .ページのテーブル中checkboxすべて選択
 * .使用方法 :
 *	  ページimport jquery.js
 *   <a href="javascript:checkAllForTable();">すべて選択</a>
 */
function checkAllForTable() {
    jQuery('table :checkbox:enabled').prop('checked',true);
}

/*
 * すべての選択を解除
 * .ページのテーブル中checkboxすべての選択を解除
 * .使用方法 :
 *	  ページimport jquery.js
 *   <a href="javascript:uncheckAllForTable();">すべての選択を解除</a>
 */
function uncheckAllForTable() {
    jQuery('table :checkbox').prop('checked',false);
}

/*
 * 削除
 * .使用checkbox削除データ
 * .使用方法 :
 *	  ページimport jquery.js
 *   function youMethod() {
 *   	if(!checkedSize()) {
 *   	}
 *   }
 */
function checkedSize() {
    // checked=trueのcheckbox数
    var size = jQuery('table :checkbox:checked').size();
    if(size == 0) {
        return false;
    } else {
        return true;
    }
}

// 別ウインドウ用スタイル
var popWindowStyle = 'height=800, width=1000, top=150, left=150, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no';

/*
 * 別ウインドウを呼び出す　関数
 *
 * @param string subwindow 別ウインドウ名前
 * @param string url URL
 */
function openPopWindow( subwindow, url ) {
    window.open( url, subwindow, popWindowStyle );
}

/*
 * 別ウインドウを呼び出し、ドキュメントを描画　関数
 *
 * @param string subwindow 別ウインドウ名前
 * @param string url URL
 * @param string data 書き込むデータ
 */
function writePopWindow( subwindow, url, data ) {
    var w = window.open( url, subwindow, popWindowStyle );
    w.document.open();
    w.document.write(data);
    w.document.close();
}

/*
 * 別ウインドウを呼び出す　関数
 *
 * @param string id  別ウィンドウで表示させたいコンポーネントID
 *
 */
function openWindowText2(id){
    // window.alert("openWindowText2");
    var text_value = document.getElementById(id).value;
    var delivery_session_key = document.getElementById("DeliveryOneTimeSessionKey").value;
    if(text_value.length == 0){
        window.alert("メール本文 （HTML）が入力されていません。");
        return;
    }
    $.post(
        "/deliveries/mail_viewer_add/" + delivery_session_key,       // リクエストURL
        {"html_body": text_value},            // データ
        function(data, status) {
            // 通信成功時にデータを表示
            if(status == 'success'){
                // window.alert("data" + data);
                var windowsource = window.open("", "popupwindow", "height=800, width=1000, top=150, left=150, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no");
                windowsource.document.open();
                windowsource.document.write(DOMPurify.sanitize(data));
                windowsource.document.close();
            }
        },
        "html"                                 // 応答データ形式
    );
}


/*
 * 別ウインドウを呼び出す　関数
 *
 * @param string id  別ウィンドウで表示させたいコンポーネントID
 *
 */
function openWindowText(id){
    var windowsource = window.open("", "popupwindow", "height=800, width=1000, top=150, left=150, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no");
    var text_value = document.getElementById(id).value;
    windowsource.document.open();
    //windowsource.document.write(text_value);
    windowsource.document.write(DOMPurify.sanitize(text_value));
    windowsource.document.close();
}

/*
 * ページのCharsetを取得します　関数
 *
 * @param なし
 * @return Charset
 */
function getPageCharset(){
    var charSet = "";
    var oType = getBrowser();
    switch(oType){
        case "IE":
            charSet = document.charset;
            break;
        case "FIREFOX":
            charSet = document.characterSet;
            break;
        default:
            charSet = document.charset;
            break;
    }
    return charSet;
}

/*
 　* ブラウザタイプを取得します　関数
 *
 * @param なし
 * @return ブラウザタイプ
 */
function getBrowser(){
    var oType = "";
    if(navigator.userAgent.indexOf("MSIE")!=-1){
        oType="IE";
    }else if(navigator.userAgent.indexOf("Firefox")!=-1){
        oType="FIREFOX";
    }else {
        //　他のブラウザ
    }
    return oType;
}

/*
 * ボタンにロールオーバーを設定　関数
 *
 * @param なし
 * @return なし
 */
function setupRolloverButton() {
    // Rollover
    if (!document.getElementsByTagName) return;

    var addEvent, switchImage, init;
    addEvent = function(obj, type, func) {
        if (obj.addEventListener) {
            obj.addEventListener(type, func, false);
        } else if (obj.attachEvent) {
            obj.attachEvent('on' + type, func);
        }
    };
    switchImage = function(event) {
        var img = event.target || event.srcElement;
        img.src = (img.src == img.srcOff) ? img.srcOn : img.srcOff;
    };
    init = function() {
        var imgs = document.getElementsByTagName('img');
        var loaded = {}, img;
        for (var i = 0, len = imgs.length; i < len; i++) {
            img = imgs[i];
            if ( !img.src.match(/^(.*_)off(\..*)$/) ) continue;
            img.srcOff = img.src;
            img.srcOn = RegExp.$1 + 'on' + RegExp.$2;
            addEvent(img, 'mouseover', switchImage);
            addEvent(img, 'mouseout', switchImage);
            if (loaded[img.srcOn]) continue;
            loaded[img.srcOn] = true;
            (new Image).src = img.srcOn;
        }
    };
    init();
}
/*
 * 日付形式チェック　関数
 *
 * @param  str 日付
 * @param  str 形式
 * @return bool
 */
function checkDateType( date, type ) {
    switch ( type ) {
        case 'YYYY/MM/DD':
            if ( !date.match( /^\d{4}\/\d{2}\/\d{2}$/ ) ) {
                return false;
            }
            break;
    }
    return true;
}
/*
 * 日付正当性チェック　関数
 *
 * @param  str 日付
 * @return bool
 */
function checkDate( date ) {
    var y = date.substr(0, 4);
    var m = date.substr(5, 2);
    var d = date.substr(8, 2);
    var dt = new Date( y, m - 1, d );
    if ( dt == null || dt.getFullYear() != y || dt.getMonth() + 1 != m || dt.getDate() != d ) {
        return false;
    }
    return true;
}

/*
 * オーナー初期設定メッセージ　関数
 *
 * @param  none
 * @return none
 */
function redirectOwnersMailSet() {
    alert("初期設定を行ってください！\n\nまずは、以下の項目を設定してください。\n・差出人(From)メールアドレス\n・返信先（reply-to）メールアドレス\n・テストメールアドレス");
    location.href = "/Owners/mail_set";
}