$(function() {
  const hrefToRootAbsolutePath = function(selector) {
    var absolutePath = selector instanceof HTMLElement ? selector.href : selector.href.baseVal
    var windowLocation = window.location
    var protocolHost = `${windowLocation.protocol}//${windowLocation.host}`

    return absolutePath.replace(protocolHost, '').replace(/index\.html$/, '')
  }

  function stLocalNav4Link() {
    // 「index」から始まり「.html」で終わる文字列を削除
    var url = location.pathname.replace(/index.*\.html$/, '');
    if($('.st-LocalNav4_Link').length) {
      $('.st-LocalNav4_Link').each(function() {
        const path = (hrefToRootAbsolutePath(this))
        if (url === path) {
          $(this).addClass('st-LocalNav4_Link-current');
        }
      });
    }
  }
  stLocalNav4Link();

  function st2LocalNav4Link() {
    // 「index」から始まり「.html」で終わる文字列を削除
    var url = location.pathname.replace(/index.*\.html$/, '');
    if($('.st2-LocalNav4_Link').length) {
      $('.st2-LocalNav4_Link').each(function() {
        const path = (hrefToRootAbsolutePath(this))
        if (url === path) {
          $(this).addClass('-is-active');
        }
      });
    }
  }
  st2LocalNav4Link();


  // ラボポータル(ラボを探す) 現在地表示(第三階層) 2020/12 add
  function stLocalNav4Link_LabPortal() {

    if($('.st-LocalNav4_Link_LabPortal').length) {
      $('.st-LocalNav4_Link_LabPortal').each(function() {
        var href = $(this).attr('href');
        if(href == 'index.html' ) {
          $(this).addClass('st-LocalNav4_Link-current_LabPortal');
        }
      });
    }
  }
  stLocalNav4Link_LabPortal();
  // ラボポータル(ラボを探す) 現在地表示(第三階層) 2020/12 add

  // ラボポータル(地図で探す) 現在地表示(第三階層) 2020/12 add
  function stLocalNav4Link_LabPortalMap () {
    var dir = location.pathname.split("/");
    var url = '/' + dir[1] + '/' + dir[2] + '/' + dir[3] + '/' + dir[4] + '/';

    if($('.st-LocalNav4_Link_LabPortalMap').length) {
      $('.st-LocalNav4_Link_LabPortalMap').each(function() {
        var href = $(this).attr('href').split("/");
        if('/' + href[1] + '/' + href[2] + '/' + href[3] + '/' + href[4] + '/' == url ) {
          $(this).addClass('st-LocalNav4_Link-current_LabPortal');
        }
      });
    }
  }
  stLocalNav4Link_LabPortalMap();
  // ラボポータル(地図で探す) 現在地表示(第三階層) 2020/12 add

  // ラボポータル(ラボを探す) 地域名表示切り替え 2020/12 add
  function stLocalNav4Link_LabPortal_Area() {

    if($('.st-LocalNavArea_Name_LabPortal').length) {
      $('.st-LocalNavArea_Name_LabPortal').each(function() {
        var href = $(this).attr('href');
        var target = document.getElementById("st-LocalTitle_LabPortal_SearchAreaName");
        var textMsg = $(this).text();
        if(href == 'index.html' || href == '../' ) {
          target.innerHTML = textMsg;
        }
      });
    }
  }
  stLocalNav4Link_LabPortal_Area();
  // ラボポータル(ラボを探す) 地域名表示切り替え 2020/12 add

  // ラボポータル(ラボを探す) 地域名表示切り替え(全国) 2020/12 add
  function stLocalNav4Link_LabPortal_AreaZenkoku() {
    var dir = location.pathname.split("/");
    var url = '/' + dir[1] + '/' + dir[2] + '/' + dir[3] + '/';

    if($('.st-LocalNavArea_Name_LabPortalZenkoku').length) {
      $('.st-LocalNavArea_Name_LabPortalZenkoku').each(function() {
        var href = $(this).attr('href');
        var target = document.getElementById("st-LocalTitle_LabPortal_SearchAreaName");
        var textMsg = $(this).text();
        if( url.includes('/zenkoku/') || href == 'index.html') {
          target.innerHTML = textMsg;
        }
      });
    }
  }
  stLocalNav4Link_LabPortal_AreaZenkoku();
  // ラボポータル(ラボを探す) 地域名表示切り替え(全国) 2020/12 add


  // ラボポータル(ラボを探す) 全国/すべての時、「地域で探す」を開いて、「条件で探す」を閉じる、「全国」の表示消す 2020/12 add
  function stLocalNav4LinkLabPortal () {
    var dir = location.pathname;
    var url = '/labplus/search/';
    var url2 = '/labplus/search/index.html';

    if(dir == url || dir == url2 ) {
      $('.st-LocalNavArea_Hidden').attr('aria-hidden', 'false');
      $('.st-LocalTitleArea_Hidden').attr('aria-expanded', 'true');
      $('.st-LocalNavCondition_Hidden').attr('aria-hidden', 'true');
      $('.st-LocalTitleCondition_Hidden').attr('aria-expanded', 'false');
      document.getElementById("st-LocalTitle_LabPortal_SearchAreaName").style.display ="none";
    }
  }
  stLocalNav4LinkLabPortal();
  // ラボポータル(ラボを探す) 全国/すべての時、「地域で探す」を開いて、「条件で探す」を閉じる、「全国」の表示消す 2020/12 add
}());
