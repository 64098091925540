$(function () {

  if ($('.js-sw-CardNavA').length) {

    var result = '';  //出力用オブジェクト

    function escapeHtml(str) {  //XSS対策
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#39;');
      return str;
    }

    try {

      $('.js-sw-CardNavA').each(function (index, element) {
        var cnt = index;
        var jsonPath = element.dataset['jsonFilePath'];
        if (jsonPath && jsonPath.length > 0) { //パスが取得できたら処理する
          $.getJSON(jsonPath)
            .done(function (json) {

              var entryJson = json['marker'];

              if (entryJson && entryJson.length > 0) {  //1件以上あったとき

                for (var i = 0; i < entryJson.length; i++) { //htmlとして出力するように整形
                  if( i >= 3 ) {break;}
                  for (var elem in entryJson[i]) { //無害化しておく
                    entryJson[i][elem] = escapeHtml(entryJson[i][elem]);
                  }

                  result += '<li class="st-Grids_Item">';

                  if (entryJson[i]['outlinksw'] && entryJson[i]['outlinksw'] === '1') { //outlinksw=1のときリンクを別タブで起動する
                    result += '<a class="sw-CardNavA" target=”_blank” ';
                  } else {
                    result += '<a class="sw-CardNavA" ';
                  }
                  result += 'href="' + entryJson[i]['event_link'] + '">';

                  result += '<div class="sw-CardNavA_Head">' +
                    '<img class="sw-CardNavA_Image" src="' +
                    entryJson[i]['event_img'] +
                    '" alt="">' +
                    '<div class="sw-CardNavA_Details">' +
                    '<div class="sw-CardNavA_Labels">';

                  if (!entryJson[i]['start_date'] || entryJson[i]['start_date'].length < 1) { //start_dateが登録されていないときは下にentry_dateを表示
                    result += '<span class="sw-CardNavA_Label sw-CardNavA_ReportLabel">';
                  } else {
                    result += '<span class="sw-CardNavA_Label">';
                  }

                  result += entryJson[i]['category_name'] +
                    '</span></div>';

                  if (entryJson[i]['start_date']) { //start_dateが入っている場合のみ
                    result += '<div class="sw-CardNavA_Date">' +
                      '<div class="sw-CardNavA_DataStart">' +
                      entryJson[i]['start_date'].slice(2, 4) + '\/' + entryJson[i]['start_date'].slice(4, 6) +//開始日
                      '<span class="sw-CardNavA_DataStartWeekday">' +
                      '(' + entryJson[i]['start_week'] + ')' + //(曜日)
                      '</span>' + '</div>';

                    if (entryJson[i]['end_date']) { //start_dateとend_date両方入ってる場合のみ
                      result += '<div class="sw-CardNavA_To" aria-label="から"></div>' +
                        '<div class="sw-CardNavA_DateEnd">' +
                        entryJson[i]['end_date'].slice(2, 4) + '\/' + entryJson[i]['end_date'].slice(4, 6) +//終了日
                        '<span class="sw-CardNavA_DateEndWeekday">' +
                        '(' + entryJson[i]['end_week'] + ')' + //(曜日)
                        '</span>' + '</div>';
                    }
                    result += '</div>' + '</div>';
                  }

                  if (entryJson[i]['endsw'] && entryJson[i]['endsw'] === '1') { //終了して入れば表示する
                    result += '<div class="sw-CardNavA_EndLabels">' +
                      '<span class="sw-CardNavA_EndLabel">終了</span>' +
                      '</div>';
                  }

                  result += '</div>' + '</div>' +
                    '<div class="sw-CardNavA_Body">' +
                    '<div class="sw-CardNavA_Text">' +
                    '<span class="sw-CardNavA_Icon" aria-hidden="true"></span>' +
                    entryJson[i]['title'] +
                    '</div>';

                  if (!entryJson[i]['start_date'] || entryJson[i]['start_date'].length < 1) { //start_dateが登録されていないときは下にentry_dateを表示
                    result += '<span class="sw-CardNavA_PostDate">' +
                      '20' + entryJson[i]['entry_date'].slice(0, 2) + '\/' + entryJson[i]['entry_date'].slice(2, 4) + '\/' + entryJson[i]['entry_date'].slice(4, 6) +
                      '</span>';
                  }

                  result += '</div>' + '</a>' + '</li>';
                }
              }
            })
            .fail(function () {
              result = ''
            })
            .always(function () {
              //出力
              var eventsContentFooter = $(element);
              eventsContentFooter.html(result);
              result = '';
            });
        }

      });

    } catch (e) {
      console.error(e.message)
    } finally {
      result = '';
    }
  }
});
