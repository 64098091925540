var ready = { api: false, ajax: false };
var map;
var mapData_zenkoku;
var mapData_kansai;
var mapData_kanto;
if (window.matchMedia('(max-width: 767px)').matches) {
  var mapOptions_zenkoku = {
    center: {
      // 地図の緯度経度
      lat: 37.0,
      lng: 135.6850225,
    },
    zoom: 3.9, // 地図の拡大率
    scrollwheel: true, // ホイール操作で拡大縮小させるかどうか
    mapTypeControl: false, // マップ切り替えのコントロールを表示するかどうか
    streetViewControl: false, // ストリートビューのコントロールを表示するかどうか
  };
  var mapOptions_kansai = {
    center: {
      // 地図の緯度経度
      lat: 34.636945,
      lng: 135.496812,
    },
    zoom: 7.0, // 地図の拡大率
    scrollwheel: true, // ホイール操作で拡大縮小させるかどうか
    mapTypeControl: false, // マップ切り替えのコントロールを表示するかどうか
    streetViewControl: false, // ストリートビューのコントロールを表示するかどうか
  };
  var mapOptions_kanto = {
    center: {
      // 地図の緯度経度
      lat: 36.003197,
      lng: 139.638499,
    },
    zoom: 6.8, // 地図の拡大率
    scrollwheel: true, // ホイール操作で拡大縮小させるかどうか
    mapTypeControl: false, // マップ切り替えのコントロールを表示するかどうか
    streetViewControl: false, // ストリートビューのコントロールを表示するかどうか
  };
} else if (window.matchMedia('(min-width:768px)').matches) {
  var mapOptions_zenkoku = {
    center: {
      // 地図の緯度経度
      lat: 36.258595,
      lng: 137.6850225,
    },
    zoom: 5.1, // 地図の拡大率
    scrollwheel: true, // ホイール操作で拡大縮小させるかどうか
    mapTypeControl: false, // マップ切り替えのコントロールを表示するかどうか
    streetViewControl: false, // ストリートビューのコントロールを表示するかどうか
  };
  var mapOptions_kansai = {
    center: {
      // 地図の緯度経度
      lat: 34.636945,
      lng: 135.496812,
    },
    zoom: 8.3, // 地図の拡大率
    scrollwheel: true, // ホイール操作で拡大縮小させるかどうか
    mapTypeControl: false, // マップ切り替えのコントロールを表示するかどうか
    streetViewControl: false, // ストリートビューのコントロールを表示するかどうか
  };
  var mapOptions_kanto = {
    center: {
      // 地図の緯度経度
      lat: 36.003197,
      lng: 139.638499,
    },
    zoom: 8.3, // 地図の拡大率
    scrollwheel: true, // ホイール操作で拡大縮小させるかどうか
    mapTypeControl: false, // マップ切り替えのコントロールを表示するかどうか
    streetViewControl: false, // ストリートビューのコントロールを表示するかどうか
  };
}


// 全国
$(function () {
  $.ajax({
    url: "/labplus/search/json/gmap_labPortal_zenkoku.json",
    cache: false,
  }).then(
    function (data) {
      mapData_zenkoku = data["data"];
      ready["ajax"] = true;
      generate_map();
    },
    function () {
      console.log("取得に失敗しました。");
    }
  );
});

// 関西
$(function () {
  $.ajax({
    url: "/labplus/search/json/gmap_labPortal_kansai.json",
    cache: false,
  }).then(
    function (data) {
      mapData_kansai = data["data"];
      ready["ajax"] = true;
      generate_map_kansai();
    },
    function () {
      console.log("取得に失敗しました。");
    }
  );
});

// 関東
$(function () {
  $.ajax({
    url: "/labplus/search/json/gmap_labPortal_kanto.json",
    cache: false,
  }).then(
    function (data) {
      mapData_kanto = data["data"];
      ready["ajax"] = true;
      generate_map_kanto();
    },
    function () {
      console.log("取得に失敗しました。");
    }
  );
});

/**
 * Google Maps APIの準備完了後の処理
 */
// 全国
function api_ready() {
  ready["api"] = true;
  generate_map();
}

// 関西
function api_ready_kansai() {
  ready["api"] = true;
  generate_map_kansai();
}

// 関東
function api_ready_kanto() {
  ready["api"] = true;
  generate_map_kanto();
}

/**
 * 地図を生成する
 */
// 全国
function generate_map() {
  if (ready["api"] && ready["ajax"]) {
    map = new google.maps.Map(document.getElementById("gmap_LabPortal_Zenkoku"), mapOptions_zenkoku);
    add_marker_zenkoku();
  }
}

// 関西
function generate_map_kansai() {
  if (ready["api"] && ready["ajax"]) {
    map = new google.maps.Map(document.getElementById("gmap_LabPortal_Kansai"), mapOptions_kansai);
    add_marker_kansai();
  }
}

// 関東
function generate_map_kanto() {
  if (ready["api"] && ready["ajax"]) {
    map = new google.maps.Map(document.getElementById("gmap_LabPortal_Kanto"), mapOptions_kanto);
    add_marker_kanto();
  }
}

/**
 * 地図にマーカーを追加する
 */
// 全国
function add_marker_zenkoku() {
  for (var i = 0; i < mapData_zenkoku.length; i++) {
    var item = mapData_zenkoku[i];

    // マーカーの設置
    var marker = new google.maps.Marker({
      position: item["latlng"],
      map: map
    });

    // 吹き出しの生成
    var ins = '<div class="st-gmap_Window">';
    ins += '<div class="st-gmap_Window_Name">' + '<a href="' + item["URL"] + '" target="_blank">'+ item["name"] + '</a>' + '</div>';
    ins += '<div class="st-gmap_Window_Content">' + item["content"] + '</div>';
    ins += '</div>';
    var infoWindow = new google.maps.InfoWindow({
      content: ins,
    });

    // マーカーのイベント設定
    add_event_to_marker_zenkoku(marker, infoWindow, i);
  }
}
// 関西
function add_marker_kansai() {
  for (var i = 0; i < mapData_kansai.length; i++) {
    var item = mapData_kansai[i];

    // マーカーの設置
    var marker = new google.maps.Marker({
      position: item["latlng"],
      map: map
    });

    // 吹き出しの生成
    var ins = '<div class="st-gmap_Window">';
    ins += '<div class="st-gmap_Window_Name">' + '<a href="' + item["URL"] + '" target="_blank">'+ item["name"] + '</a>' + '</div>';
    ins += '<div class="st-gmap_Window_Content">' + item["content"] + '</div>';
    ins += '</div>';
    var infoWindow = new google.maps.InfoWindow({
      content: ins,
    });

    // マーカーのイベント設定
    add_event_to_marker_kansai(marker, infoWindow, i);
  }
}

// 関東
function add_marker_kanto() {
  for (var i = 0; i < mapData_kanto.length; i++) {
    var item = mapData_kanto[i];

    // マーカーの設置
    var marker = new google.maps.Marker({
      position: item["latlng"],
      map: map
    });

    // 吹き出しの生成
    var ins = '<div class="st-gmap_Window">';
    ins += '<div class="st-gmap_Window_Name">' + '<a href="' + item["URL"] + '" target="_blank">'+ item["name"] + '</a>' + '</div>';
    ins += '<div class="st-gmap_Window_Content">' + item["content"] + '</div>';
    ins += '</div>';
    var infoWindow = new google.maps.InfoWindow({
      content: ins,
    });

    // マーカーのイベント設定
    add_event_to_marker_kanto(marker, infoWindow, i);
  }
}

/**
 * マーカーにイベントを追加する
 * @param {object} marker     (required) マーカーの情報
 * @param {object} infoWindow (required) 吹き出しの情報
 * @param {number} index      (required) 地図情報のインデックス番号
 */
// 全国
function add_event_to_marker_zenkoku(marker, infoWindow, index) {
  var item = mapData_zenkoku[index];
  item["marker"] = marker;
  item["infoWindow_zenkoku"] = infoWindow;

  // マーカークリック時に吹き出しを表示する
  item["marker"].addListener("click", function (e) {
    infoWindows_hide_zenkoku();
    item["infoWindow_zenkoku"].open(map, item["marker"]);
  });
}

// 関西
function add_event_to_marker_kansai(marker, infoWindow, index) {
  var item = mapData_kansai[index];
  item["marker"] = marker;
  item["infoWindow_kansai"] = infoWindow;

  // マーカークリック時に吹き出しを表示する
  item["marker"].addListener("click", function (e) {
    infoWindows_hide_kansai();
    item["infoWindow_kansai"].open(map, item["marker"]);
  });
}

// 関東
function add_event_to_marker_kanto(marker, infoWindow, index) {
  var item = mapData_kanto[index];
  item["marker"] = marker;
  item["infoWindow_kanto"] = infoWindow;

  // マーカークリック時に吹き出しを表示する
  item["marker"].addListener("click", function (e) {
    infoWindows_hide_kanto();
    item["infoWindow_kanto"].open(map, item["marker"]);
  });
}

/**
 * 吹き出しを非表示にする
 */
// 全国
function infoWindows_hide_zenkoku() {
  for (var i = 0; i < mapData_zenkoku.length; i++) {
    mapData_zenkoku[i]["infoWindow_zenkoku"].close();
  }
}

// 関西
function infoWindows_hide_kansai() {
  for (var i = 0; i < mapData_kansai.length; i++) {
    mapData_kansai[i]["infoWindow_kansai"].close();
  }
}

// 関東
function infoWindows_hide_kanto() {
  for (var i = 0; i < mapData_kanto.length; i++) {
    mapData_kanto[i]["infoWindow_kanto"].close();
  }
}
