$(function() {

  if($('.st2-LocalNav_Heading').length) {
    $('.st2-LocalNav_Heading').on('click',function(){
      if(window.innerWidth >= 980) {
      } else {
        if($(this).hasClass("-is-open")) {
          $(this).removeClass("-is-open");
          $('.st2-LocalNav_Inner').removeClass("-is-open");
          $(this).next().slideUp();
        } else {
          $(this).addClass("-is-open");
          $('.st2-LocalNav_Inner').addClass("-is-open");
          $(this).next().slideDown();
        }
      }
    });
  }
  if($('.st2-LocalNav_SubItems_Heading').length) {
    $('.st2-LocalNav_SubItems_Heading').on('click',function(){
      if(window.innerWidth >= 980) {
      } else {
        if($(this).hasClass("-is-open")) {
          $(this).removeClass("-is-open");
          $(this).next().slideUp();
        } else {
          $(this).addClass("-is-open");
          $(this).next().slideDown();
        }
        return false;
      }
    });
  }

  if($('.sw2-LocalNavi').length) {
    $(window).on('scroll load', function () {
      if ($(window).scrollTop() > $('.sw2-LocalNavi').offset().top + $('.sw2-LocalNavi').outerHeight()) {
        $('.st2-LocalNav').addClass("-is-fixed");
      } else {
        $('.st2-LocalNav').removeClass("-is-fixed");
      }
    });
  }

}());
