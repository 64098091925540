$(function () {

  /**
   * 企業検索の詳細ページ
   * 同じ業種の企業を3件表示する
   * 記事id昇順で自分の記事idから開始して3件取得
   */

  if ($('.js-company-search-detail').length) {
    var result = '';  //出力用オブジェクト
    var pickups = {
      'feature' : '特集記事',
      'ichioshi' : 'イチオシ',
      'collaboration' : '連携ウェルカム',
      'established' : 'KRPで創業'
    };

    function escapeHtml(str) {  //XSS対策

      if(str instanceof Object) {return str;}
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#39;');
      return str;
    }

    try {
      var jsonPath = $('.js-company-search-detail').attr('data-json-file-path'); //jsonのファイルパスを取得
      var companyCategory = $('.js-company-search-detail').attr('data-company-category'); //カテゴリを取得
      var companyId = $('.js-company-search-detail').attr('data-company-id'); //記事idを取得

      if (jsonPath && jsonPath.length > 0 && companyCategory.length > 0) { //パス&カテゴリが取得できたら処理する
        $.getJSON(jsonPath)
          .done(function (json) {

            //同じ業種に絞り込む
            var sameCategoryCompany = [];
            var after =[];  //後回しにする企業

            for(var i = 0; i < json.length; i++){
              if(json[i]['id'] == companyId) {continue;}  //表示中の企業は省く
              var category = json[i]['category']['industry'];
              var eq = category.some(function(value){
                return companyCategory === value;
              });
              if(eq && companyId > json[i]['id']){
                after.push(json[i]);  //今のページのidより小さければ後回しにする
              } else if(eq) {
                sameCategoryCompany.push(json[i]);
              }
            }
            //id順に並び替え
            sameCategoryCompany.sort(function(x, y){
              return x.id - y.id;
            });
            after.sort(function(x, y){
              return x.id - y.id;
            });

            //自分のidから数えて3件取得する、最新のidまで達した場合は一番古いidまで戻って補完する
            var resultCompany = []; //表示する企業
            for(var i = 0; i < sameCategoryCompany.length; i++){
                resultCompany.push(sameCategoryCompany[i]);
                if(resultCompany.length >= 3) { break;}  //3件集まったらforを抜ける
            }

            //表示する企業が3件に満たない場合、後回しにした企業から補完する
            if(resultCompany.length < 3) {
               var cnt = 3 - resultCompany.length;
              for(var i = 0; i < cnt; i++){
                resultCompany.push(after[i]);
              }
            }

            if (resultCompany && resultCompany.length > 0) {  //表示する企業が1件以上存在する場合
              for (var i = 0; i < resultCompany.length; i++) { //件数分表示する

                if(!resultCompany[i]) {continue;}

                for (var elem in resultCompany[i]) { //無害化しておく
                  resultCompany[i][elem] = escapeHtml(resultCompany[i][elem]);
                }
                var snipet = resultCompany[i]['snipet'];
                if(snipet.length > 150) {
                  snipet = snipet.substr(0,150) + '...';  //151文字移行を削除＋...をつける
                }
                result += '<div class="st-Block st-Block-divider">' +
                    '<div class="sw-MediaA sw-MediaA-middle sw-MediaA-col9to3Lg">' +
                    '<div class="sw-MediaA_Body">' +
                    '<a class="sw-MediaA_Heading" href="' + resultCompany[i]['link'] + '">' +
                    '<span class="sw-MediaA_HeadingIcon" aria-hidden="true"></span>' +
                    resultCompany[i]['title'] +
                    '</a>' +
                    '<p class="sw-MediaA_Text">' + snipet + '</p>';
                // pickupアイコンを表示を日本語で表示 なかったらulから出力しない
                if(resultCompany[i]['category']['pickup'].length > 1) {
                  result += '<ul class="st-Label sw-MediaA_Text">';
                  for( var j = 0; j < resultCompany[i]['category']['pickup'].length; j++) {
                    var pickupText = pickups[resultCompany[i]['category']['pickup'][j]];  //日本語表記に変換して表示
                    result += '<li class="st-Label_Item"><span class="sw-Label">' +
                        pickupText + '</span></li>';
                  }
                  result += '</ul>';
                }

                //特集記事を表示 ない場合olから出力しない
                if(resultCompany[i]['feature1']['url'] || resultCompany[i]['feature2']['url'] || resultCompany[i]['feature3']['url']){
                  result += '<ol class="sw-ListArrow sw-MediaA_Text">';
                  for(var k = 1; k < 4; k ++) {
                    var feature = 'feature' + k;
                    if(resultCompany[i][feature]['title'] && resultCompany[i][feature]['title'].length > 0) {
                      result += '<li class="sw-ListArrow_Item">' +
                          '<span class="sw-ListArrow_Icon" aria-hidden="true"></span>' +
                          '<a class="sw-ListArrow_Link" href="'+
                          resultCompany[i]['feature' + k]['url'] + '" target="_blank" rel="noopener noreferrer">' +
                          resultCompany[i]['feature' + k]['title'] + '</a>' +
                          '</li>';
                    }
                  }
                  result += '</ol>';
                }
                result += '</div>';

                if(resultCompany[i]['img']) { //画像がなかったらdivから出力しない
                  result += '<div class="sw-MediaA_ImageArea">' +
                      '<img src="' + resultCompany[i]['img'] + '" alt="" class="sw-MediaA_Image">' +
                      '</div>';
                }
                result += '</div></div>';
              }
            } else {  //1件もなかった場合何もしない
            }
          })
          .fail(function () {
            result = ''
          })
          .always(function () { //出力
            var eventsContentFooter = $('.js-company-search-detail');
            eventsContentFooter.html(result);
          });
      }
    } catch (e) {
      console.error(e.message)
    } finally {
      result = '';
    }
  }
});
