$(function() {

  if($('.sw-CardNavD_Head').length) {
    $('.sw-CardNavD_Head').matchHeight();
  }

  if($('.sw-CardNavD_Item').length) {
    $('.sw-CardNavD_Item').matchHeight();
  }

}());
