$(function() {

  $('.sw-LightboxA_Link').colorbox({
    maxWidth: '900px',
    width: '89.333333333%', // 40px / 375px
    opacity: '0.7',
    inline: true,
    rel:'sw-LightboxA_Link',
    transition: 'fade',
    speed: 150,
    close: '',
    previous: '',
    next: '',
    current: ''
  });

  $('.sw-LightboxA_Close').click(function(e){
    parent.$.fn.colorbox.close();
    e.stopPropagation();
  });

  $('.sw-LightboxA_Prev').click(function(e){
    parent.$.fn.colorbox.prev();
    e.stopPropagation();
  });
  $('.sw-LightboxA_Next').click(function(e){
    parent.$.fn.colorbox.next();
    e.stopPropagation();
  });

}());
