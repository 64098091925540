$(function() {

  /**
   * ID: FL-A
   * 要素の絞り込みができるモジュールです。
   * 「すべて」（`data-filter=""`）を選択すると、すべての要素が表示されます。
   * 個別に選択されている場合は、選択されたカテゴリを含んだ記事が表示されます。
   */
  var dataFilter = (function() {
    // 初期値で「すべて」を選択する。
    $('.krpweek-FilterNav_Button[data-filter=""]')
      .addClass('krpweek-FilterNav_Button-selected')
      .attr('aria-selected', 'true');

    $('.krpweek-FilterNav_Button').on('click', function(e) {
      // 非表示を切り替える記事をすべて取得する。
      var $article = $('.krpweek-FilterBlock_Item');
      // クリックした要素から値を取得する
      var navValue = $(this).data('filter');
      // カレントをリセットする
      $('.krpweek-FilterNav_Button')
        .removeClass('krpweek-FilterNav_Button-selected')
        .attr('aria-selected', 'false');
      // クリックした要素にカレントをセットする
      $(this)
        .addClass('krpweek-FilterNav_Button-selected')
        .attr('aria-selected', 'true');
      // いったん要素をすべて非表示にする
      $article.css('display', 'none');

      // 「すべて」を選択したら、すべての要素を表示する
      if(navValue === '') {
        $article
          .css('display', '');
      // それ以外を選択したら、navValueを含む要素を表示する
      } else {
        for(var i = 0; i < $article.length; i++) {
          var $this = $article.eq(i);
          var ItemValue = $this.data('filter');
          var ItemValueLength = ItemValue.length;

          for(var j = 0; j < ItemValueLength; j++) {
            if(navValue === ItemValue[j]) {
              $this.css('display', '');
            }
          }
        }
      }
      e.stopPropagation();
      });
  })();

}());
