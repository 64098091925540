$(function() {

  if($('.sw-SliderB_Area').length) {
    var slideCount;
    $('.sw-SliderB_Area').bxSlider({
      mode: 'fade',
      speed: 500,
      pause: 4000,
      captions: false,
      infiniteLoop: false,
      hideControlOnEnd: true,
      nextText: 'Next',
      prevText: 'Previous',
      onSliderLoad: function(currentIndex) {
        // 画像の数を取得
        slideCount = $('.sw-SliderB .bx-pager-item').length;
        // Prevの初期値にクラスを追加する。
        $('.sw-SliderB .bx-prev').addClass('current');
      },
      onSlideAfter: function(el, active, old) {
        var activeCount = old + 1;
        // 最初の要素を表示している時はPrevにクラスを追加する。
        if(activeCount === 1) {
          $('.sw-SliderB .bx-prev').addClass('current');
        } else {
          $('.sw-SliderB .bx-prev').removeClass('current');
        }
        // 最後の要素を表示している時はNextにクラスを追加する。
        if(slideCount === activeCount) {
          $('.sw-SliderB .bx-next').addClass('current');
        } else {
          $('.sw-SliderB .bx-next').removeClass('current');
        }
      }
    });
  }

}());
