$(function() {
  //マウスオーバーでメガメニューを表示
  var $HeaderNavChild = $('.st-HeaderNav_Child');

  $('[data-child]').on('mouseenter',function(){
    $this = $(this);
    sethover = setTimeout(function(){
      $('.st-HeaderNavChild_Item').removeClass('is-open');
      HeaderNavChild_data = $this.data('child');
      $('.' + HeaderNavChild_data).addClass('is-open');
      $HeaderNavChild.slideDown('fast');
    },300);
  }).mouseleave(function(e) {
    //メガメニュー非表示
    clearTimeout(sethover);
    if (e.relatedTarget == $('.st-Header_NavArea')[0]) return;
    if (e.relatedTarget == $('.st-HeaderNav_Items')[0]) return;
    if (e.relatedTarget == $('.st-HeaderNav_Child')[0]) return;
    HeaderNavChild_data = '';
    $HeaderNavChild.stop(false, true).fadeOut(200, function() {
      $('.st-HeaderNavChild_Item').removeClass('is-open');
    });
  });
  // メガメニュー非表示
  $HeaderNavChild.on('mouseleave',function(e){
    HeaderNavChild_data = '';
    $HeaderNavChild.stop(false, true).fadeOut(200, function() {
      $('.st-HeaderNavChild_Item').removeClass('is-open');
    });
  });
}());

