$(function() {

  if($('.sw-VisualNavC_FootItem').length) {
    $('.sw-VisualNavC_FootItem').matchHeight();
  }

  if($('.sw-VisualNavC_FootImageArea').length) {
    $('.sw-VisualNavC_FootImageArea').matchHeight();
  }

  if($('.sw-VisualNavC_FootTextArea').length) {
    $('.sw-VisualNavC_FootTextArea').matchHeight();
  }

}());
