// レンタルラボプラス イベントページ用

$(function () {
  if ($(".js-sw-CardNavALabplus02").length) {
    var result = ""; //出力用オブジェクト

    function escapeHtml(str) {
      //XSS対策
      str = str.replace(/&/g, "&amp;");
      str = str.replace(/</g, "&lt;");
      str = str.replace(/>/g, "&gt;");
      str = str.replace(/"/g, "&quot;");
      str = str.replace(/'/g, "&#39;");
      return str;
    }

    // 開催中のイベント用 最大12件出力
    try {
      $(".js-sw-CardNavALabplus02.js-labplusEventplans").each(function (
        index,
        element
      ) {
        var cnt = index;
        var jsonPath = element.dataset["jsonFilePath"];
        if (jsonPath && jsonPath.length > 0) {
          //パスが取得できたら処理する
          $.getJSON(jsonPath)
            .done(function (json) {
              var entryJson = json["marker"];

              if (entryJson && entryJson.length > 0) {
                //1件以上あったとき

                for (var i = 0; i < entryJson.length; i++) {
                  //htmlとして出力するように整形
                  if (i >= 12) {
                    break;
                  }
                  for (var elem in entryJson[i]) {
                    //無害化しておく
                    entryJson[i][elem] = escapeHtml(entryJson[i][elem]);
                  }

                  result += '<li class="st-Grids_Item">';
                  result += '<a class="sw-CardNavALabplus02" target=”_blank” ';
                  result += 'href="' + entryJson[i]["event_link"] + '">';

                  result +=
                    '<div class="sw-CardNavALabplus02_Head">' +
                    '<img class="sw-CardNavALabplus02_Image" src="' +
                    entryJson[i]["event_img"] +
                    '" alt="">' +
                    '<div class="sw-CardNavALabplus02_Details">' +
                    '<div class="sw-CardNavALabplus02_Labels">';

                  if (
                    !entryJson[i]["start_date"] ||
                    entryJson[i]["start_date"].length < 1
                  ) {
                    //start_dateが登録されていないときは下にentry_dateを表示
                    result +=
                      '<span class="sw-CardNavALabplus02_Label sw-CardNavALabplus02_ReportLabel">';
                  } else {
                    result += '<span class="sw-CardNavALabplus02_Label">';
                  }

                  result += entryJson[i]["category_name"] + "</span></div>";

                  if (entryJson[i]["start_date"]) {
                    //start_dateが入っている場合のみ
                    result +=
                      '<div class="sw-CardNavALabplus02_Date">' +
                      '<div class="sw-CardNavALabplus02_DataStart">' +
                      entryJson[i]["start_date"].slice(2, 4) +
                      "/" +
                      entryJson[i]["start_date"].slice(4, 6) + //開始日
                      '<span class="sw-CardNavALabplus02_DataStartWeekday">' +
                      "(" +
                      entryJson[i]["start_week"] +
                      ")" + //(曜日)
                      "</span>" +
                      "</div>";

                    if (entryJson[i]["end_date"]) {
                      //start_dateとend_date両方入ってる場合のみ
                      result +=
                        '<div class="sw-CardNavALabplus02_To" aria-label="から"></div>' +
                        '<div class="sw-CardNavALabplus02_DateEnd">' +
                        entryJson[i]["end_date"].slice(2, 4) +
                        "/" +
                        entryJson[i]["end_date"].slice(4, 6) + //終了日
                        '<span class="sw-CardNavALabplus02_DateEndWeekday">' +
                        "(" +
                        entryJson[i]["end_week"] +
                        ")" + //(曜日)
                        "</span>" +
                        "</div>";
                    }
                    result += "</div>" + "</div>";
                  }

                  if (entryJson[i]["endsw"] && entryJson[i]["endsw"] === "1") {
                    //終了して入れば表示する
                    result +=
                      '<div class="sw-CardNavALabplus02_EndLabels">' +
                      '<span class="sw-CardNavALabplus02_EndLabel">終了</span>' +
                      "</div>";
                  }

                  result +=
                    "</div>" +
                    "</div>" +
                    '<div class="sw-CardNavALabplus02_Body">' +
                    '<div class="sw-CardNavALabplus02_Text">' +
                    '<span class="sw-CardNavALabplus02_Icon" aria-hidden="true"></span>' +
                    entryJson[i]["title"] +
                    "</div>";

                  if (
                    !entryJson[i]["start_date"] ||
                    entryJson[i]["start_date"].length < 1
                  ) {
                    //start_dateが登録されていないときは下にentry_dateを表示
                    result +=
                      '<span class="sw-CardNavALabplus02_PostDate">' +
                      "20" +
                      entryJson[i]["entry_date"].slice(0, 2) +
                      "/" +
                      entryJson[i]["entry_date"].slice(2, 4) +
                      "/" +
                      entryJson[i]["entry_date"].slice(4, 6) +
                      "</span>";
                  }

                  result += "</div>" + "</a>" + "</li>";
                }
              }
            })
            .fail(function () {
              result = "";
            })
            .always(function () {
              //出力
              var eventsContentFooter = $(element);
              eventsContentFooter.html(result);
              result = "";
            });
        }
      });
    } catch (e) {
      console.error(e.message);
    } finally {
      result = "";
    }

    // 開催終了のイベント用 最大48件出力
    try {
      $(".js-sw-CardNavALabplus02.js-labplusEventreports").each(function (
        index,
        element
      ) {
        var cnt = index;
        var jsonPath = element.dataset["jsonFilePath"];
        if (jsonPath && jsonPath.length > 0) {
          //パスが取得できたら処理する
          $.getJSON(jsonPath)
            .done(function (json) {
              var entryJson = json["marker"];

              if (entryJson && entryJson.length > 0) {
                //1件以上あったとき

                for (var i = 0; i < entryJson.length; i++) {
                  //htmlとして出力するように整形
                  if (i >= 48) {
                    break;
                  }
                  for (var elem in entryJson[i]) {
                    //無害化しておく
                    entryJson[i][elem] = escapeHtml(entryJson[i][elem]);
                  }

                  result += '<li class="st-Grids_Item">';
                  result += '<a class="sw-CardNavALabplus02" target=”_blank” ';
                  result += 'href="' + entryJson[i]["event_link"] + '">';

                  result +=
                    '<div class="sw-CardNavALabplus02_Head">' +
                    '<img class="sw-CardNavALabplus02_Image" src="' +
                    entryJson[i]["event_img"] +
                    '" alt="">' +
                    '<div class="sw-CardNavALabplus02_Details">' +
                    '<div class="sw-CardNavALabplus02_Labels">';

                  if (
                    !entryJson[i]["start_date"] ||
                    entryJson[i]["start_date"].length < 1
                  ) {
                    //start_dateが登録されていないときは下にentry_dateを表示
                    result +=
                      '<span class="sw-CardNavALabplus02_Label sw-CardNavALabplus02_ReportLabel">';
                  } else {
                    result += '<span class="sw-CardNavALabplus02_Label">';
                  }

                  result += entryJson[i]["category_name"] + "</span></div>";

                  if (entryJson[i]["start_date"]) {
                    //start_dateが入っている場合のみ
                    result +=
                      '<div class="sw-CardNavALabplus02_Date">' +
                      '<div class="sw-CardNavALabplus02_DataStart">' +
                      entryJson[i]["start_date"].slice(2, 4) +
                      "/" +
                      entryJson[i]["start_date"].slice(4, 6) + //開始日
                      '<span class="sw-CardNavALabplus02_DataStartWeekday">' +
                      "(" +
                      entryJson[i]["start_week"] +
                      ")" + //(曜日)
                      "</span>" +
                      "</div>";

                    if (entryJson[i]["end_date"]) {
                      //start_dateとend_date両方入ってる場合のみ
                      result +=
                        '<div class="sw-CardNavALabplus02_To" aria-label="から"></div>' +
                        '<div class="sw-CardNavALabplus02_DateEnd">' +
                        entryJson[i]["end_date"].slice(2, 4) +
                        "/" +
                        entryJson[i]["end_date"].slice(4, 6) + //終了日
                        '<span class="sw-CardNavALabplus02_DateEndWeekday">' +
                        "(" +
                        entryJson[i]["end_week"] +
                        ")" + //(曜日)
                        "</span>" +
                        "</div>";
                    }
                    result += "</div>" + "</div>";
                  }

                  if (entryJson[i]["endsw"] && entryJson[i]["endsw"] === "1") {
                    //終了して入れば表示する
                    result +=
                      '<div class="sw-CardNavALabplus02_EndLabels">' +
                      '<span class="sw-CardNavALabplus02_EndLabel">終了</span>' +
                      "</div>";
                  }

                  result +=
                    "</div>" +
                    "</div>" +
                    '<div class="sw-CardNavALabplus02_Body">' +
                    '<div class="sw-CardNavALabplus02_Text">' +
                    '<span class="sw-CardNavALabplus02_Icon" aria-hidden="true"></span>' +
                    entryJson[i]["title"] +
                    "</div>";

                  if (
                    !entryJson[i]["start_date"] ||
                    entryJson[i]["start_date"].length < 1
                  ) {
                    //start_dateが登録されていないときは下にentry_dateを表示
                    result +=
                      '<span class="sw-CardNavALabplus02_PostDate">' +
                      "20" +
                      entryJson[i]["entry_date"].slice(0, 2) +
                      "/" +
                      entryJson[i]["entry_date"].slice(2, 4) +
                      "/" +
                      entryJson[i]["entry_date"].slice(4, 6) +
                      "</span>";
                  }

                  result += "</div>" + "</a>" + "</li>";
                }
              }
            })
            .fail(function () {
              result = "";
            })
            .always(function () {
              //出力
              var eventsContentFooter = $(element);
              eventsContentFooter.html(result);
              result = "";
            });
        }
      });
    } catch (e) {
      console.error(e.message);
    } finally {
      result = "";
    }
  }
});
