$(function() {

  if($('.st2-ContentHeaderA_MainText').length) {
    var parentHeight = $('.st2-ContentHeaderA_MainText').height();
    if (parentHeight > 124) {
      $('.st2-ContentHeaderA_MainText').css('margin-top','-174px');
    } else {
      $('.st2-ContentHeaderA_MainText').css('margin-top','-124px');
    }
    $(window).on('resize', function () {
      var parentHeight = $('.st2-ContentHeaderA_MainText').height();
      if (parentHeight > 124) {
        $('.st2-ContentHeaderA_MainText').css('margin-top','-174px');
      } else {
        $('.st2-ContentHeaderA_MainText').css('margin-top','-124px');
      }
    });
  }

  if(!$('.st2-ContentHeaderA').length) {
    $('body').addClass('-is-nomv')
  }

}());
