$(function() {

  /**
   * 印刷ボタン（`.js-print-button`）をクリックすると、直近の親要素（`.js-print-area`）だけ印刷します。
   * 印刷するエリアは`.js-print-additional-area`で1つだけ増やすことができます。
   */
  $('.js-print-button').on('click', function() {
    var $this = $(this);
    var $printArea = $this.closest('.js-print-area').html();
    var $printAdditionalArea = $('.js-print-additional-area').html();
    var $content = $('body').html();

    $('body').append('<div id="print"></div>');
    $('#print').append($printArea);
    if($('.js-print-additional-area').length) {
      $('#print').append($printAdditionalArea);
    }
    $('body > :not(#print)').css('display', 'none');
    window.print();

    $('#print').remove();
    $('body > :not(#print)').css('display', '');
  });

  /**
   * イベント・ニュース系　詳細ページはコンテンツ部分のみ印刷する。
   */
  var addPrintExcluded = (function() {
    var locationPath = location.pathname;
    // URLに以下のキーワードが入っているページが対象になる。
    var path = [
      "detail"
    ];
    var hasDirName = false;

    // `path`の値がURLに含まれている場合にtrueを返す。
    for(var i = 0; i < path.length; i++) {
      if(locationPath.match(path[i])) {
        hasDirName = true;
        break;
      }
    }

    if(hasDirName) {
      // 記事本文の`aricle`要素内だけ印刷する。
      $('body > *:not(main)').addClass('print-Excluded');
      $('body > main > :not(".st-ContainerD")').addClass('print-Excluded');
    }
  })();

}());
