$(function () {

  function companySearch() {
    $('.js-companySearchMenu').on('click', function() {
      var $this = $(this);
      var $nav = $('#companySearch-menuContent');
      if ($this.hasClass('is-close')) {
        $nav.slideDown(300);
        $this.removeClass('is-close');
      } else {
        $nav.slideUp(300);
        $this.addClass('is-close');
      }
    });
  }
  companySearch();

  function companySearchInput() {
    $('.js-companySearchInput').focus(function() {
      var $menu = $('.js-companySearchMenu');
      var $nav = $('#companySearch-menuContent');
      if ($menu.hasClass('is-close')) {
        $nav.slideDown(300);
        $menu.removeClass('is-close');
      }
    });
  }
  companySearchInput();

  // function companySearch() {
  //   $('.js-companySearchMenu[aria-expanded]').on('click', function() {
  //     var $this = $(this);
  //     var $nav = $('#' + $this.attr('aria-controls'));
  //     var expanded = $this.attr('aria-expanded');

  //     if(expanded === 'false') {
  //       $this.attr('aria-expanded', 'true');
  //       $nav.slideDown(300).attr('aria-hidden', 'false');
  //     } else {
  //       $this.attr('aria-expanded', 'false');
  //       $nav.slideUp(300).attr('aria-hidden', 'true');
  //     }
  //   });
  // }
  // companySearch();

  // function companySearchInput() {
  //   $('.js-companySearchInput').focus(function() {
  //     var $menu = $('.js-companySearchMenu[aria-expanded]');
  //     var $nav = $('#' + $menu.attr('aria-controls'));
  //     var expanded = $menu.attr('aria-expanded');

  //     if(expanded === 'false') {
  //       $menu.attr('aria-expanded', 'true');
  //       $nav.slideDown(300).attr('aria-hidden', 'false');
  //     }
  //   })
  // }
  // companySearchInput();

});

