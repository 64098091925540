$(function() {
  if($('.krpweek-fb').length) {
    var fbWrap = $('.krpweek-fb');
    var fbWidth = '';
    var htmlStr = fbWrap.html();
    var timer = null;

    $(window).on('resize',function() {
      var fbResizeWidth = $('.krpweek-fb').width();
      if(fbWidth != fbResizeWidth && fbResizeWidth <= 500) {
          clearTimeout(timer);
          timer = setTimeout(function() {
              $('.krpweek-fb').html(htmlStr);
              window.FB.XFBML.parse();
              //window.FB.XFBML.parse()で再レンダリングします。
              fbWidth = $('.krpweek-fb').width();
          }, 500);
      }
    });
  }
}());
