$(function() {

  $(window).on('load', function() {
    $('html[lang="ja"] .st-HeaderSearch_Input .gsc-input').attr('placeholder', 'キーワードで検索する');
    $('html[lang="en"] .st-HeaderSearch_Input .gsc-input').attr('placeholder', 'Search KRP');
    $('html[lang="zh-cmn-Hans"] .st-HeaderSearch_Input .gsc-input').attr('placeholder', 'Search KRP');
    $('html[lang="ko"] .st-HeaderSearch_Input .gsc-input').attr('placeholder', 'Search KRP');
  });

}());
