$(function() {

  if($('#serviceofficeMapEng').length) {
        google.maps.event.addDomListener(window, 'load', function() {

            var lat = 34.9950414; //座標
            var lng = 135.7387209; //座標

            var latlng = new google.maps.LatLng(lat, lng);
            var mapOptions = {
                zoom: 16,
                center: latlng,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scaleControl: true
            };
            var mapObj = new google.maps.Map(document.getElementById('serviceofficeMapEng'), mapOptions);
            var marker = new google.maps.Marker({
                position: latlng,
                map: mapObj
            });
        });

  }
}());
