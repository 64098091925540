$(function() {

  function stLocalNav2Link() {
    var url = location.pathname;
    if($('.st-LocalNav2_Link').length) {
      $('.st-LocalNav2_Link').each(function() {
        if($(this).attr('href') == url ) {
          $(this).addClass('st-LocalNav2_Link-current');
        }
      });
    }
  }
  stLocalNav2Link();

  function setStLocalNav2() {
    if(window.innerWidth >= 980) {
      $('.st-LocalNav2_Title').css('pointer-events', 'none');
    } else {
      $('.st-LocalNav2_Title').css('pointer-events', '');
    }
  }

  function stLocalNav2() {
    $('.st-LocalNav2_Title[aria-expanded]').on('click', function() {
      var $this = $(this);
      var $nav = $('#' + $this.attr('aria-controls'));
      var expanded = $this.attr('aria-expanded');

      if(expanded === 'false') {
        $this.attr('aria-expanded', 'true');
        $nav.slideDown(300).attr('aria-hidden', 'false');
      } else {
        $this.attr('aria-expanded', 'false');
        $nav.slideUp(300).attr('aria-hidden', 'true');
      }
    });
  }
  stLocalNav2();

  $(window).on('resize', _.throttle(function() {
    setStLocalNav2();
  }, 300));

   // ラボポータル(地域・条件で探す) 現在地表示(第二階層)
   function stLocalNav2Link_LabPortal () {

    if($('.st-LocalNav2_Link_LabPortal').length) {
      $('.st-LocalNav2_Link_LabPortal').each(function() {
        var href = $(this).attr('href');
        if(href == 'index.html' || href == '../') {
          $(this).addClass('st-LocalNav4_Link-current_LabPortal');
        }
      });
    }
  }
  stLocalNav2Link_LabPortal();
  // ラボポータル(地域・条件で探す) 現在地表示(第二階層)

  // ラボポータル(地域・条件で探す) 現在地表示(第二階層) 全国用
   function stLocalNav2Link_LabPortal_Zenkoku () {
     var dir = location.pathname.split("/");
     var url = '/' + dir[1] + '/' + dir[2] + '/' + dir[3] + '/';

    if($('.st-LocalNav2_Link_LabPortalZenkoku').length) {
      $('.st-LocalNav2_Link_LabPortalZenkoku').each(function() {
        var href = $(this).attr('href');
        if( url.includes('/zenkoku/') || href == 'index.html') {
          $(this).addClass('st-LocalNav4_Link-current_LabPortal');
        }
      });
    }
  }
  stLocalNav2Link_LabPortal_Zenkoku();
  // ラボポータル(地域・条件で探す) 現在地表示(第二階層) 全国用 END

  // ラボポータル(地図で探す) 現在地表示(第二階層)
  function stLocalNav2Link_LabPortalMap () {
    var dir = location.pathname.split("/");
    var url = '/' + dir[1] + '/' + dir[2] + '/' + dir[3] + '/' + dir[4] + '/';

    if($('.st-LocalNav2_Link_LabPortalMap').length) {
      $('.st-LocalNav2_Link_LabPortalMap').each(function() {
        var href = $(this).attr('href').split("/");
        if('/' + href[1] + '/' + href[2] + '/' + href[3] + '/' + href[4] + '/' == url ) {
          $(this).addClass('st-LocalNav4_Link-current_LabPortal');
        }
      });
    }
  }
  stLocalNav2Link_LabPortalMap();
  // ラボポータル(地図で探す) 現在地表示(第二階層) END
}());
