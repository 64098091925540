$(function() {

  if($('.-is-show-btn').length) {
    $(window).on('load', function() {
      if($('.-is-show-btn').find('.st-Grids_Item').length <= 3) {
        $('.-is-show-btn').find('.st2-TitleBox_ShowBtn').hide();
        if($('.st2-TitleBox_Button').length) {
          $('.st2-TitleBox').css('padding-bottom','75px');
        }
      } else {
        $('.-is-show-btn').find('.st2-TitleBox_Button').hide();
      }
    });

    $('.st2-TitleBox_ShowBtn').on('click', function(e) {
      $(this).hide();
      $('.st2-TitleBox_Main').find('.st-Grids_Item').css('display','inline-block');
      if($('.st2-TitleBox_Button').length) {
        $('.st2-TitleBox').css('padding-bottom','75px');
        $('.st2-TitleBox_Button').show();
      }
    });
  }

}());
