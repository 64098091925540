$(function() {

  function setStLocalTitleA() {
    if(window.innerWidth >= 980) {
      $('.st-LocalTitleA').css('pointer-events', 'none');
    } else {
      $('.st-LocalTitleA').css('pointer-events', '');
    }
  }

  function stLocalTitle() {
    $('.st-LocalTitleA[aria-expanded]').on('click', function() {
      var $this = $(this);
      var $nav = $('#' + $this.attr('aria-controls'));
      var expanded = $this.attr('aria-expanded');

      if(expanded === 'false') {
        $this.attr('aria-expanded', 'true');
        $nav.slideDown(300).attr('aria-hidden', 'false');
      } else {
        $this.attr('aria-expanded', 'false');
        $nav.slideUp(300).attr('aria-hidden', 'true');
      }
    });
  }
  stLocalTitle();

  setStLocalTitleA();
  $(window).on('resize', _.throttle(function() {
    setStLocalTitleA();
  }, 300));


}());

$(function() {

  function setStLocalTitleSearch() {
    if(window.innerWidth >= 980) {
      $('.st-LocalTitle_LabPortal_Search').css('pointer-events', 'none');
    } else {
      $('.st-LocalTitle_LabPortal_Search').css('pointer-events', '');
    }
  }

  // ラボを探す ナビクリックで開閉 2020/12 add
  function stLocalTitleSearch() {
    $('.st-LocalTitle_LabPortal_Search[aria-expanded]').on('click', function() {
      var $this = $(this);
      var $nav = $('#' + $this.attr('aria-controls'));
      var expanded = $this.attr('aria-expanded');

      if(expanded === 'false') {
        $this.attr('aria-expanded', 'true');
        $nav.slideDown(300).attr('aria-hidden', 'false');
      } else {
        $this.attr('aria-expanded', 'false');
        $nav.slideUp(300).attr('aria-hidden', 'true');
      }
    });
  }
  stLocalTitleSearch();
  // ラボを探す ナビクリックで開閉 2020/12 add

  // ラボを探す スクロールでナビを閉じる 2020/12 add
  function stLocalTitleSearchScrolldown() {
    $(window).scroll(function() {
      $('.st-LocalTitle_LabPortal_Search').attr('aria-expanded', 'false');
      $('.st-LocalNav4_LabPortal.st-LocalNavArea_Hidden').css('display', 'none');
      $('.st-LocalNav4_LabPortal.st-LocalNavCondition_Hidden').css('display', 'none');
    });
  }
  stLocalTitleSearchScrolldown();
  // ラボを探す スクロールでナビを閉じる 2020/12 add

  setStLocalTitleSearch();
  $(window).on('resize', _.throttle(function() {
    setStLocalTitleSearch();
  }, 300));


}());
