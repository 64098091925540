$(function () {

  if ($('.sw-CardNavE_Items').length) {

    var result = '';  //出力用オブジェクト

    function escapeHtml(str) {  //XSS対策
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#39;');
      return str;
    }

    try {

      var jsonPath = $('.sw-CardNavE_Items').attr('data-json-file-path');
      if (jsonPath && jsonPath.length > 0) { //パスが取得できたら処理する
        $.getJSON(jsonPath)
          .done(function (json) {

            var entryJson = json['marker'];

            if (entryJson && entryJson.length > 0) {  //1件以上あったとき

              for (var i = 0; i < 3; i++) { //htmlとして出力するように整形

                for (var elem in entryJson[i]) { //無害化しておく
                  entryJson[i][elem] = escapeHtml(entryJson[i][elem]);
                }

                result += '<li class="sw-CardNavE_Item">';

                if (entryJson[i]['outlinksw'] && entryJson[i]['outlinksw'] === '1') { //outlinksw=1のときリンクを別タブで起動する
                  result += '<a class="sw-CardNavE_Link" target=”_blank” ';
                } else {
                  result += '<a class="sw-CardNavE_Link" ';
                }
                result += 'href="' + entryJson[i]['event_link'] + '">';

                result += '<div class="sw-CardNavE_Head">' +
                  '<img class="sw-CardNavE_Image" src="' +
                  entryJson[i]['event_img'] +
                  '" alt=""></div>' +
                  '<div class="sw-CardNavE_Body">' +
                  '<div class="sw-CardNavE_Text">' +
                  entryJson[i]['title'] +
                  '</div>' + '</div>' + '</a>' +'</li>';
              }
            }
          })
          .fail(function () {
            result = ''
          })
          .always(function () {
            //出力
            var eventsContentFooter = $('.sw-CardNavE_Items');
            eventsContentFooter.html(result);
          });
      }

    } catch (e) {
      console.error(e.message)
    } finally {
      result = '';
    }
  }
});
