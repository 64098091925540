$(function() {

  if($('.sw2-PageLinkImage').length) {

    $('.sw2-PageLinkImage_LinkListItem').on('mouseenter', function () {
      if (window.innerWidth >= 980) {
        var $linkImageItem = $('.sw2-PageLinkImage_LinkImageItem');
        var $activeLinkImageItem = $linkImageItem.eq($(this).closest('li').index());
        $activeLinkImageItem.stop().animate({ opacity: 1 }, { duration: 1000, easing: 'linear' });
        $linkImageItem.not($activeLinkImageItem).stop().animate({ opacity: 0 }, { duration: 1000, easing: 'linear' });
      }
    });

    $(window).on('load resize orientationchange', function () {
      $('.sw2-PageLinkImage_LinkImageIn.slick-slider').slick('unslick');
      if (window.innerWidth >= 980) {
      } else {
        $('.sw2-PageLinkImage_LinkImageItem').css('opacity', 1);
        $('.sw2-PageLinkImage_LinkImageIn').slick({
          adaptiveHeight: false,
          autoplay: true,
          autoplaySpeed: 2000,
          pauseOnFocus: false,
          pauseOnHover: false,
          pauseOnDotsHover: false,
          swipe: false,
          dots: false,
          fade: true,
          speed: 2000,
          arrows: false
        });
      }
    });

    $('.sw2-PageLinkImage_LinkList .-is-no-events').on('click', function () {
      return false;
    });
  }

}());
