$(function() {

  if($('.sw-TableB_Table-fixedLeft').length) {
    $('.sw-TableB_Table-fixedLeft').tableHeadFixer({
      'head': false,
      'z-index': 10,
      'left': 1
    });
  }

  if($('.sw-TableB_Table-fixedHead').length) {
    $('.sw-TableB_Table-fixedHead').tableHeadFixer();
  }

}());
