$(function() {

  if($('.sw-CardNavA').length) {

      /**
       * 確保している高さよりも低い画像はスタイルを追加してトリミングする。
       */
      $('.sw-CardNavA_Head').each(function() {
        var parentHeight = $(this).innerHeight();
        var imageHeight = $(this).find($('.sw-CardNavA_Image')).innerHeight();

        if(parentHeight > imageHeight) {
          $(this).find($('.sw-CardNavA_Image')).css({
            'max-height': '100%',
            'width': 'auto',
            'max-width': 'none'
          });
        }
      });

  }

}());
