$(function() {

  if($('.sw-QandA').length) {
    $('.sw-QandA').accordion({
      'tabs': '.sw-QandA_QuestionButton',
      'tabpanels': '.sw-QandA_Answer',
      'useRole': false,
      'openFirstChild': true,
      'multiselectable': true,
      'tabClass': 'is-active',
      'panelClass': 'is-active',
      afterKeyEvent: function(event, target) {
        $(target).addClass('focus-ring');
      }
    });
  }

}());
