$(function () {

    if ($('.st-Grids').length) {

      function basename(path) {
        return path.replace(/\\/g,'/').replace( /.*\//, '' );
      }
      function dirname(path) {
        return path.replace(/\\/g,'/').replace(/\/[^\/]*$/, '') + '/';
      }

      var st2gridsdirName = dirname(location.pathname);
      var st2gridsfileName = basename(location.href);
      var st2gridsisMatchDir = false;
      var st2gridsisSecondPage = false;
      // MT管理でページ送りが表示されるディレクトリを渡します。
      var st2gridspath = [
        "/news/",
        "/news/news/",
        "/news/krpeople/",
        "/_news/",
        "/_news/news/",
        "/_news/krpeople/",
      ];
      for(var i = 0; i < st2gridspath.length; i++) {
        if(st2gridspath[i] === st2gridsdirName) {
          st2gridsisMatchDir = true;
          break;
        }
      }
      if(st2gridsfileName !== '' && st2gridsfileName !== 'index.html') {
        st2gridsisSecondPage = true;
      }
      if(st2gridsisMatchDir && st2gridsisSecondPage) {
        $('.st-Grids.st-Grids-col3Lg').removeClass('-is-first-col2Lg');
      }
  
    }
});
