$(function () {

  /**
   * 各詳細ページにイベントやニュースの最新３件表示する
   * htmlのjsonから取得＋表示させる
   */

  if ($('.js-companyDetail').length) {
    var result = '';  //出力用オブジェクト

    function escapeHtml(str) {  //XSS対策
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#39;');
      return str;
    }

    try {
      var jsonPath = $('.js-companyDetail').attr('data-json-file-path'); //htmlに埋め込まれたjsonのファイルパスを取得
      if (jsonPath && jsonPath.length > 0) { //パスが取得できたら処理する
        $.getJSON(jsonPath)
          .done(function (json) {

            var entryJson = json['marker'];

            result += '<div class="st-Wrapper">' +  //見出し
              '<h2 class="sw-Heading2B sw-Heading2B-dark st-Heading2">' +
              escapeHtml(entryJson[0]['areatitle']) +
              '</h2>';

            if (entryJson && entryJson.length > 0) {  //json内にデータが1件以上あったとき
              result += '<ul class="sw-CardNavE_Items">';

              for (var i = 0; i < 3; i++) { //jsonデータの3件目まで処理を行う

                for (var elem in entryJson[i]) { //無害化しておく
                  entryJson[i][elem] = escapeHtml(entryJson[i][elem]);
                }

                result += '<li class="sw-CardNavE_Item">';

                if (entryJson[i]['outlinksw'] && entryJson[i]['outlinksw'] === '1') { //outlinksw=1のときリンクを別タブで起動する
                  result += '<a class="sw-CardNavE_Link" target=”_blank” ';
                } else {
                  result += '<a class="sw-CardNavE_Link" ';
                }
                result += 'href="' + entryJson[i]['event_link'] + '">';

                result += '<div class="sw-CardNavE_Head">' +
                  '<img class="sw-CardNavE_Image" src="' +
                  entryJson[i]['event_img'] +
                  '" alt="">' + '</div>' +
                  '<div class="sw-CardNavE_Body">' +
                  '<div class="sw-CardNavE_Text">' +
                  entryJson[i]['title'] +
                  '</div>' + '</div>' + '</a>' + '</li>';
              }
              result += '</ul>';
            } else {  //1件もなかった場合何もしない
            }

            result += '<div class="st-ContainerE_Foot">' +  //ボタン
              '<a href="' + entryJson[0]['btnurl'] + '" class="sw-Button sw-Button-invert">' +
              entryJson[0]['btntitle'] +
              '<span class="sw-Button_RightArrow" aria-hidden="true"></span>' +
              '</a></div></div>' +
              '</footer>';
          })
          .fail(function () {
            result = ''
          })
          .always(function () { //出力
            var eventsContentFooter = $('.js-companyDetail');
            eventsContentFooter.html(result);
          });
      }
    } catch (e) {
      console.error(e.message)
    } finally {
      result = '';
    }
  }
});
