$(function() {

  function krpweekFilterNav() {
    if(window.innerWidth >= 980) {
      $('.krpweek-FilterNav_Title').css('pointer-events', 'none');
    } else {
      $('.krpweek-FilterNav_Title').css('pointer-events', '');
    }
  }

  function krpweekFilterNavTitle() {
    $('.krpweek-FilterNav_Title[aria-expanded]').on('click', function() {
      var $this = $(this);
      var $nav = $('#' + $this.attr('aria-controls'));
      var expanded = $this.attr('aria-expanded');

      if(expanded === 'false') {
        $this.attr('aria-expanded', 'true');
        $nav.slideDown(300).attr('aria-hidden', 'false');
      } else {
        $this.attr('aria-expanded', 'false');
        $nav.slideUp(300).attr('aria-hidden', 'true');
      }
    });
  }
  krpweekFilterNavTitle();

  $(window).on('resize', _.throttle(function() {
    krpweekFilterNav();
  }, 300));

  if($('.krpweek-FilterNav_Item').length) {
    $('.krpweek-FilterNav_Item').matchHeight();
  }


}());
