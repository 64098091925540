$(function() {

  function stHeaderToggleMenu() {
    var $this = $('.st-HeaderToggleMenu');
    var controls = $this.attr('aria-controls');
    var $nav = $('#' + controls);
    var $header = $('.st-Header');

    function fixed() {
      $('html, body').css({
        'width': '100%',
        'height': '100%',
        'overflow': 'hidden',
        'position': 'fixed',
        'top': '0',
        'left': '0'
      });
    }

    function removeFixed() {
      $('html, body').css({
        'width': '',
        'height': '',
        'overflow': '',
        'position': '',
        'top': '',
        'left': ''
      });
    }

    var init = (function() {
      $this.attr('aria-expanded', false);
      $nav.attr('aria-hidden', true);
      $header.removeClass('st-Header-spMenuOpen');
      removeFixed();
    }());

    function show(speed) {
      var slideSpeed = speed || 400;
      fixed();
      $this.attr('aria-expanded', true);
      $nav.attr('aria-hidden', false).slideDown(slideSpeed);
      $header.addClass('st-Header-spMenuOpen');
    }

    function hide(speed) {
      var slideSpeed = speed || 400;
      removeFixed();
      $this.attr('aria-expanded', false);
      $nav.slideUp(slideSpeed).attr('aria-hidden', true);
      $header.removeClass('st-Header-spMenuOpen');
    }

    var scrollPosition;
    $this.on('click', function(e) {
      if($(this).attr('aria-expanded') === 'false') {
        scrollPosition = $(window).scrollTop();
        show();
      } else {
        hide();
      }
      if(scrollPosition >= 0) {
        window.scroll(0, scrollPosition);
      }
      e.stopPropagation();
    });

    // PC幅のときはハンバーガーメニューを非表示にする。
    $(window).resize(function() {
      if(window.innerWidth >= 980) {
        hide(0);
      }
    });
  }
  stHeaderToggleMenu();
}());
