$(function() {

  /**
   * ローカルヘッダー（`.st-ContentHeaderB`）をスクロール量やスクロールの方向によって固定します。
   * 一定以上スクロールしている場合と、上方向にスクロールしている場合にヘッダーは固定されます。
   */
  var stContentHeaderB = (function() {
    $(window).on('load', function() {
      // 一定以上スクロールしている場合はtrue、それ以外はfalse。`stHeaderFixedUpTo()`で値が動的に変更される。
      var defaultValueOrMore = false;
      // 下スクロールしたときに表示する高さ
      var defaultValue = 1000;
      // 上方向にスクロールしたらtrue、下方向にスクロールしたらfalse。`var isScrollUp`で値が動的に変更される。
      var scrolledToTop = false;
      var startPos = 0;
      // 固定表示させる要素
      var $fixed = $('.st-ContentHeaderB_Block-toFix');
      // 固定するときにつけるクラス名。
      var fixedClass = 'st-ContentHeaderB_Block-fixed';
      // ヘッダーの高さを格納する。`var isHeaderHeight`で値が動的に変更される。
      var headerHeight = $fixed.outerHeight();

      // 一定以上スクロールしている場合はtrue、それ以外はfalseを返す。
      function stHeaderFixedUpTo() {
        var scrolledUpToDefaultValue = (function() {
          var scroll = $(window).scrollTop();
          if(scroll >= defaultValue) {
            defaultValueOrMore = true;
          } else {
            defaultValueOrMore = false;
          }
        })();
      }

      // 上方向にスクロールしたらtrueを返す。
      var isScrollUp = (function() {
        $(window).scroll(function(){
          var currentPos = $(this).scrollTop();
          if (currentPos > startPos) {
            scrolledToTop = false;
          } else {
            scrolledToTop = true;
          }
          startPos = currentPos;
        });
      })();

      // ヘッダーの高さを返す。
      var isHeaderHeight = (function() {
        $(window).on('resize', function() {
          headerHeight = $fixed.outerHeight();
        });
      })();

      var setClass = (function() {

        $(window).on('scroll', _.throttle(function() {
          stHeaderFixedUpTo();
          var scroll = $(this).scrollTop();

          // 上スクロールかつスクロール量が一定以上
          if(scrolledToTop && scroll >= headerHeight) {
            $fixed.addClass(fixedClass);
            // 上スクロールかつスクロール量が一定未満
          } else if(scrolledToTop && scroll < headerHeight) {
            $fixed.removeClass(fixedClass);
            // スクロール量が一定以上
          } else if(scrolledToTop === false && defaultValueOrMore === true) {
            $fixed.addClass(fixedClass);
          }
        }, 20));
      })();
    });
  })();

}());
