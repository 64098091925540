$(function() {

  if ($('[data-scroll-header]').length > 1) {
    $('[data-scroll-header]').eq(0).removeAttr('data-scroll-header');
  }

  smoothScroll.init({
    selector: '[data-scroll]', // スムーススクロールが有効なリンクに付ける属性
    selectorHeader: '[data-scroll-header]', // 固定ナビに付ける属性
    speed: 500, // 到達するまでの総時間(ミリ秒)
    easing: 'easeInOutCubic', // イージングの種類
    offset: 0, // 到達場所からズラすピクセル数
    updateURL: true // 実行後のURLの末尾に#をつけるか
  });

  if($('.st-PageLink_Link').length) {
    $('.st-PageLink_Link').matchHeight();
  }

  // 直接URLで飛んできた場合の処理
  if (location.hash) {
    var hash = document.querySelector(location.hash);
    if (hash != null) {
      var options = {
        speed: 0 // 到達するまでの総時間(ミリ秒)
      };
      $(window).on('load', function () {
        smoothScroll.animateScroll(hash, null, options);
      });
    }
  }

  // 同一ページのリンクにも対応するための処理
  $('.-anc-link').on('click', function () {
    const href = $(this).attr('href').split('#')
    if (href[1] === undefined || href[1] === '') {
      return
    }

    if (href[0] !== '') {
      let from, to
      if (href[0].startsWith('/')) {
        // 絶対パス
        from = location.pathname.replace('index.html', '').replace(/\/+/g, '/').replace(/\/$/, '')
        to = href[0].replace('index.html', '').replace(/\/+/g, '/').replace(/\/$/, '')
      } else {
        // 相対パス
        from = location.pathname.endsWith('.html')
          ? location.pathname.split('/').pop()
          : 'index.html'
        to = href[0]
      }
      if (from !== to) {
        return
      }
    }

    var hash = document.querySelector('#' + href[1]);
    if (hash != null) {
      if($('.st2-LocalNav_Heading').hasClass("-is-open")) {
        $('.st2-LocalNav_Heading').removeClass("-is-open");
        $('.st2-LocalNav_Inner').removeClass("-is-open");
        $('.st2-LocalNav_Heading').next().slideUp(0, function () {
          $('#st2-LocalNav').removeClass('-is-fixed');
          smoothScroll.animateScroll(hash);
        });
      } else {
        $('#st2-LocalNav').removeClass('-is-fixed');
        smoothScroll.animateScroll(hash);
      }
      return false;
    }
  })

}());
