$(function() {

  if($('.st2-FooterCopyright_PageTop').length) {
    $(window).on('load', function() {
      var defaultValue = 1000;
      var $fixed = $(".st2-FooterCopyright_PageTop");
      // 固定するときにつけるクラス名。
      var fixedClass = 'st2-FooterCopyright_PageTop-fixed';

      $(window).on('load scroll', _.throttle(function() {

        if($(this).scrollTop() >= defaultValue) {
          $fixed.addClass(fixedClass);
        } else {
          $fixed.removeClass(fixedClass);
        }
      }, 20));
    });

    $('.st2-FooterCopyright_PageTopLink').on('click', function(e) {
      $('html, body').animate({scrollTop: 0}, 500, 'swing');
      e.preventDefault();
    });
  }

}());
