$(function () {
  if($('.home-kv_image-js').length) {
    $('.home-kv_image-js').each(function(index) {
      $('.home-kv_image-js').eq(index).slick({
          arrows: false,
          infinite: true,
          swipe: false,
          autoplay: true,
          autoplaySpeed: 3200,
          speed: 800,
          pauseOnHover: false,
          slidesToShow: 1,
          slidesToScroll: 1,
      });
    });
  }
});
