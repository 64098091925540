$(function() {

  /**
   * グローバルナビゲーションかローカルナビゲーションのいずれかを固定表示対象にする。
   * 第1階層以下すべてがグローバルナビの対象になる場合は`globalDir`の配列にルート相対パスで指定します。
   * 特定のディレクトリがグローバルナビの対象になる場合は`globalPage`の配列にルート相対パスで指定します。
   */
  var stHeaderFixedSet = (function() {
    var url = location.pathname.replace(/index\.html$/, '');
    var urlDir1 = '/' + url.split("/")[1] + '/';
    var onlyGlobalNav = true;
    var flagGlobal = false;
    var localNav = ['.st-LocalNav1', '.st-LocalNav2', '.st-LocalNav3'];
    var contentHeader = ['st-ContentHeaderA_Block', 'st-ContentHeaderB_Block', 'st-ContentHeaderD_Block', 'st-ContentHeaderE_Block', 'st-ContentHeaderG_Block'];
    var globalDir = [
      '/about/',
      '/events/',
      '/_events/',
      '/company/',
      '/access/',
      '/shops/',
      '/outline/',
      '/pub/',
      '/news/',
      '/sitemap/',
      '/privacy/',
      '/copyright/'
    ];
    var globalPage = [
      '/',
      '/sangaku/',
      '/sangaku/dschool/',
      '/sangaku/dic/',
      '/english/',
      '/english//basic_facts/',
      '/english//basic_facts/history/',
      '/english//basic_facts/about_kyoto/',
      '/english/support_activities//new_business_creation/',
      '/english/support_activities/business_support/',
      '/english/access_to_krp/',
      '/english/access_to_krp//krp_area_map/',
      '/english/inquiry/',
      '/english/inquiry/check.php',
      '/english/inquiry/thanks.html',
    ];

    // グローバルヘッダーに固定表示するクラスを追加する。
    function setGlobalFixedClass() {
      $('.st-Header').addClass('st-Header-fixed').attr('data-scroll-header', '');
    }

    // ページに存在する`localNav`があれば、その親となる`contentHeader`にクラスを追加する。
    function setLocalFixedClass() {
      for(var i = 0; i < localNav.length; i++) {
        var $nav = $(localNav[i]);
        if($nav.length) {
          for(var j = 0; j < contentHeader.length; j++) {
            var $header = $('.' + contentHeader[j]);
            if($header.length) {
              $header.addClass(contentHeader[j] + '-toFix').attr('data-scroll-header', '');
            }
          }
        }
      }
    }

    // ローカルナビが存在しない場合は`onlyGlobalNav`を`false`にする。
    var exitLocalNav = (function() {
      for(var i = 0; i < localNav.length; i++) {
        if($(localNav[i]).length){
          onlyGlobalNav = false;
          break;
        };
      };
    }());

    // ローカルナビが存在する場合かつ、第一階層に`globalDir`が含まれていれば`flagGlobal`を`true`にする。
    var searchGlobalDir = (function() {
      if(!onlyGlobalNav) {
        for(var i = 0; i < globalDir.length; i++) {
          if(urlDir1 === globalDir[i]) {
            flagGlobal = true;
          }
        }
      }
    }());

    // ローカルナビが存在する場合かつ、第一階層に`globalDir`が含まれていなかった場合で、`globalPage`と`url`が同じときは`flagGlobal`を`true`にする。
    var searchGlobalPage = (function() {
      if(!onlyGlobalNav && !flagGlobal) {
        for(var i = 0; i < globalPage.length; i++) {
          if(url === globalPage[i]) {
            flagGlobal = true;
          }
        }
      }
    }());

    // ローカルナビがない場合か特定のディレクトリ・ページの場合はグローバルヘッダーを固定する。
    if(onlyGlobalNav || flagGlobal) {
      setGlobalFixedClass();
    } else {
      // それ以外のディレクトリ・ページはローカルナビのいずれかを固定する。
      setLocalFixedClass();
    }
  }());

  /**
   * グローバルヘッダーをスクロール量やスクロールの方向によって固定します。
   * 一定以上スクロールしている場合と、上方向にスクロールしている場合にヘッダーは固定されます。
   * 固定させない場合は、`.st-Header-fixed`を外します。
   */
  var stHeaderFixed = (function() {
    // 一定以上スクロールしている場合はtrue、それ以外はfalse。`stHeaderFixedUpTo()`で値が動的に変更される。
    var defaultValueOrMore = false;
    // 下スクロールしたときに表示する高さ
    var defaultValue = 1000;
    // 上方向にスクロールしたらtrue、下方向にスクロールしたらfalse。`var isScrollUp`で値が動的に変更される。
    var scrolledToTop = false;
    var startPos = 0;
    // 固定表示させる要素
    var $fixed = $('.st-Header-fixed');
    // 固定するときにつけるクラス名。
    var fixedClass = 'st-Header-fixedActive';
    // ヘッダーの高さを格納する。`var isHeaderHeight`で値が動的に変更される。
    var headerHeight = $('.st-Header').outerHeight();

    // 一定以上スクロールしている場合はtrue、それ以外はfalseを返す。
    function stHeaderFixedUpTo() {
      var scrolledUpToDefaultValue = (function() {
        var scroll = $(window).scrollTop();
        if(scroll >= defaultValue) {
          defaultValueOrMore = true;
        } else {
          defaultValueOrMore = false;
        }
      })();
    }

    // 上方向にスクロールしたらtrueを返す。
    var isScrollUp = (function() {
      $(window).scroll(function(){
        var currentPos = $(this).scrollTop();
        if (currentPos > startPos) {
          scrolledToTop = false;
        } else {
          scrolledToTop = true;
        }
        startPos = currentPos;
      });
    })();

    // ヘッダーの高さを返す。
    var isHeaderHeight = (function() {
      $(window).on('resize', function() {
        headerHeight = $('.st-Header').outerHeight();
      });
    })();

    var setClass = (function() {
      var $siteLogo = $('.st-HeaderLogo_SiteLogoImage');
      var $siteName = $('.st-HeaderLogo_SiteNameImage');
      var siteLogoImage = $siteLogo.attr('src');
      var siteNameImage = $siteName.attr('src');
      var replaceSiteLogoImage = '/assets/img/common/logo_krp-red.svg'
      var replaceSiteNameImage = '/assets/img/common/logo_red-krp-txt.svg';

      // デフォルトの画像を変更する。
      function replaceImages() {
        if(window.innerWidth >= 980) {
          $siteLogo.attr('src', replaceSiteLogoImage);
          $siteName.attr('src', replaceSiteNameImage);
        }
      }
      // デフォルトの画像に変更する。
      function undoImages() {
        if(window.innerWidth >= 980) {
          $siteLogo.attr('src', siteLogoImage);
          $siteName.attr('src', siteNameImage);
        }
      }

      $(window).on('scroll', _.throttle(function() {
        stHeaderFixedUpTo();
        var scroll = $(this).scrollTop();

        // 上スクロールかつスクロール量が一定以上
        if(scrolledToTop && scroll >= headerHeight) {
          $fixed.addClass(fixedClass);
          // 上スクロールかつスクロール量が一定未満
        } else if(scrolledToTop && scroll < headerHeight) {
          $fixed.removeClass(fixedClass);
          // スクロール量が一定以上
        } else if(scrolledToTop === false && defaultValueOrMore === true) {
          $fixed.addClass(fixedClass);
        }
      }, 20));
    })();

  })();

}());
