$(function() {

  var dataUpdateLabel = (function() {
    // 該当モジュールのchildNodeがある場合
    if($('.sw-CardNavA_UpdateLabels').length) {

      /**
       * @param {date} 現在日と比較したい日付
       * @return {Number} 比較日から現在日までの経過している日数（整数）
       */
      var elapsedDays = function (date) {
        var current = new Date();
        var date = new Date(date);
        return Math.ceil((current - date) / 86400000);
      };

      $('.sw-CardNavA_UpdateLabels').each(function() {
        var $this = $(this);
        var dataAttr = $this.data('update-label');

        // カスタムデータ属性があった場合、経過日数を計算して、4日以上経っていたら要素を非表示にする。
        if(dataAttr !== undefined) {
          var diff = elapsedDays(new Date(dataAttr));
          if(diff >= 4) {
            $this.hide();
          }
        }
      });
    }
  })();
}());
