// $(function() {
//   var BlogClassRemove = (function() {
//     /**
//      * イベント系の記事一覧の2ページ以降の最近の記事を表示しない
//      */
//     var remove = (function() {
//       // URLからファイル名だけを取得する
//       function basename(path) {
//         return path.replace(/\\/g,'/').replace( /.*\//, '' );
//       }
//       // URLからディレクトリだけを取得する
//       function dirname(path) {
//         return path.replace(/\\/g,'/').replace(/\/[^\/]*$/, '') + '/';;
//       }

//       var locationPath = location.pathname;
//       var dirName = dirname(location.pathname);
//       var fileName = basename(location.href);
//       var isMatchDir = false;
//       var isSecondPage = false;
//       // MT管理でページ送りが表示されるディレクトリを渡します。
//       var path = [
//         "/labplus/blog/"
//       ];

//       // 特定のディレクトリと一致したらtrueを返す。
//       for(var i = 0; i < path.length; i++) {
//         if(path[i] === dirName) {
//           isMatchDir = true;
//           break;
//         }
//       }

//       // 最後が/か/index.html以外であればtrueを返す。
//       if(fileName == '') {
//         isSecondPage = true;
//       }

//       // 条件に合致する場合は要素を削除する。
//       if(isMatchDir && isSecondPage) {
//         $('.events-Nav1_Item').removeClass('events-Nav1_Item_LabPortal');
//       }
//     })();
//   })();

// }());
