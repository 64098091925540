$(function() {

  if($('.sw-CardNavC_BodyInner').length) {
    $('.sw-CardNavC_BodyInner').matchHeight();

    /**
     * 確保している高さよりも低い画像はスタイルを追加してトリミングする。
     */
    $('.sw-CardNavC_Block').each(function() {
      var parentHeight = $(this).innerHeight();
      var imageHeight = $(this).find($('.sw-CardNavC_Image')).innerHeight();

      if(parentHeight > imageHeight) {
        $(this).find($('.sw-CardNavC_Image')).css({
          'max-height': '100%',
          'width': 'auto',
          'max-width': 'none'
        });
      }
    });

  }

}());
