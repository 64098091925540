$(function() {

  if($('.sw-CardNavB_BodyInner').length) {
    $('.sw-CardNavB_BodyInner').matchHeight();

      /**
       * 確保している高さよりも低い画像はスタイルを追加してトリミングする。
       */
      $('.sw-CardNavB_Block').each(function() {
        var parentHeight = $(this).innerHeight();
        var imageHeight = $(this).find($('.sw-CardNavB_Image')).innerHeight();

        if(parentHeight > imageHeight) {
          $(this).find($('.sw-CardNavB_Image')).css({
            'max-height': '100%',
            'width': 'auto',
            'max-width': 'none'
          });
        }
      });

  }

}());
