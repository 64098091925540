$(function() {

  if($('.sw2-MvSlider').length) {
    $('.sw2-MvSlider_In').on('init', function(){
      if(!($('.sw2-EventLabel').length)){
          $('.slick-one-area').addClass('slick-one-noevent');
      }
      $('.slick-slide[data-slick-index="0"]').addClass('slick-moving');
      $('.slick-dots').wrap('<div class="slick-nav">');
    }).slick({
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      swipe: false,
      dots: true,
      fade: true,
      speed: 4000,
      arrows: false
    }).on('beforeChange',function(event, slick, currentSlide, nextSlide){
      var slide_num = $('.slick-slide').length;
      $('.slick-slide[data-slick-index="'+ ( currentSlide - 1 ) +'"]').removeClass('slick-moving');
      $('.slick-slide[data-slick-index="'+nextSlide+'"]').addClass('slick-moving');
      if( currentSlide == 0 ){
        $('.slick-slide[data-slick-index="'+ ( slide_num - 1 ) +'"]').removeClass('slick-moving');
      }
    });
  }

}());
