$(function() {
  if ($('.sw-FullCalendar').length) {
    function dateZeroPadding(date) {
      return "0".concat(date).slice(-2);
    }

    var today = new Date();
    var dateRanges = showPrevDateRange(today);

    function showPrevDateRange(today) {
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var prevMonth = month === 1 ? 12 : month - 1;
      var nextMonth = month === 12 ? 2 : month + 2;
      var prevYear = month === 1 ? year - 1 : year;
      var nextYear = month === 12 ? year + 1 : year;
      return [prevYear + "-" + dateZeroPadding(prevMonth) + "-01", nextYear + "-" + dateZeroPadding(nextMonth) + "-01"];
    }

    var mqlSP = window.matchMedia('(max-width:767px)');
    var aspectRatio = mqlSP.matches ? 0.6 : 1.35;
    var ua = window.navigator.userAgent;
    var eventDidMount;

    if (ua.indexOf('Trident') !== -1 || ua.indexOf('MSIE') !== -1) {
      eventDidMount = function eventDidMount() {
        return;
      };
    } else {
      if (typeof tippy !== 'undefined') {
        eventDidMount = function eventDidMount(e) {
          tippy(e.el, {
            content: e.event.title
          });
        };
      } else {
        eventDidMount = function eventDidMount() {
          return;
        };
      }
    }

    var eventSources = [];

    $.ajax({
      url: "/events/json/events-calendarEvent.json",
      success: function success(data) {
        if (data.length > 0) {
          eventSources = [{
            events: data,
            className: "event1"
          }];
        }
      },
      error: function error() {
        eventSources = [{
          url: "/_events/json/events-calendarEvent.json",
          className: "event1"
        }];
      },
      complete: function complete() {
        var calendarEl = document.getElementById("event-calendar");
        var calendar = new FullCalendar.Calendar(calendarEl, {
          initialView: "dayGridMonth",
          locale: "ja",
          validRange: {
            start: dateRanges[0],
            end: dateRanges[1]
          },
          eventSources: eventSources,
          eventTimeFormat: {
            hour: "numeric",
            minute: "2-digit",
            meridiem: false
          },
          // displayEventTime: false,
          dayCellContent: function dayCellContent(e) {
            // $('.fc-today-button').text("今日");
            return e.dayNumberText = e.dayNumberText.replace("日", "");
          },
          eventDidMount: eventDidMount,
          aspectRatio: aspectRatio,
          eventClick: function(info) {
            if (info.event.url && /^http/.test(info.event.url)) {
              info.jsEvent.preventDefault();
              window.open(info.event.url);
            }
          }
        });
        calendar.render();
      }
    });
  }
}());
