/**
 * `display: none;`によって簡易的に要素を絞り込みます。
 * 親要素に`data-krpfes-filter-root`を、絞り込むためのリンクに`data-krpfes-filter-link`を、絞り込むコンテンツのリンクに`data-krpfes-filter-content`を設定してください。
 * `data-krpfes-filter-link`と同じ値を持つ`data-krpfes-filter-content`だけが表示されます。
 * ページ内に複数の`data-krpfes-filter-root`があっても、それぞれが独立して動作します。
 * @example
 * <div data-krpfes-filter-root>
 *   <div class="nav">
 *     <ul>
 *       <li><a href="#" data-krpfes-filter-link="0">すべて</a></li>
 *       <li><a href="#" data-krpfes-filter-link="1">ビジネス</a></li>
 *     </ul>
 *   </div>
 *   <div class="contents">
 *     <ul>
 *       <li><a href="#" data-krpfes-filter-content="0">すべて</a></li>
 *       <li><a href="#" data-krpfes-filter-content="1">ビジネス</a></li>
 *     </ul>
 *   </div>
 * </div>
 */
$(function () {
  // ページロード時の処理
  const roots = $('[data-krpfes-filter-root]');

  if (roots.length === 0) {
    return;
  }

  roots.each(function () {
    const root = $(this);
    const links = $('[data-krpfes-filter-link]', root);
    const contents = $('[data-krpfes-filter-content]', root);

    links.attr('aria-current', 'false').removeClass('-is-active');
    links.first().attr('aria-current', 'true').addClass('-is-active');

    // 絞り込み処理
    links.on('click', function (event) {
      event.preventDefault();
      const currentLink = $(this);
      const currentLinkValue = currentLink.data('krpfes-filter-link');

      links.attr('aria-current', 'false').removeClass('-is-active');
      currentLink.attr('aria-current', 'true').addClass('-is-active');

      if (currentLinkValue === 0) {
        contents.css('display', '');
      } else {
        contents.css('display', 'none');
        contents.each(function () {
          const currentContent = $(this);
          const currentContentValue = currentContent.data('krpfes-filter-content');
          if (currentContentValue === currentLinkValue) {
            currentContent.css('display', '');
          }
        });
      }
    });
  });
});
