$(function () {

  /**
   * 企業検索結果に企業特集のの最新６件表示する
   * htmlのjsonから取得＋表示させる
   */

  if ($('.js-companyDetailForSearch').length) {
    var result = '';  //出力用オブジェクト

    function escapeHtml(str) {  //XSS対策
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#39;');
      return str;
    }

    try {
      var jsonPath = $('.js-companyDetailForSearch').attr('data-json-file-path'); //htmlに埋め込まれたjsonのファイルパスを取得
      if (jsonPath && jsonPath.length > 0) { //パスが取得できたら処理する
        $.getJSON(jsonPath)
          .done(function (json) {

            var entryJson = json['marker'];

            if (entryJson && entryJson.length > 0) {  //json内にデータが1件以上あったとき
              result += '<h2 class="sw-Heading2B sw-Heading2B-dark st-Heading2">特集企業</h2>' +
                  '<ul class="st-Grids st-Grids-col3Lg st-Block">';

              for (var i = 0; i < 6; i++) { //jsonデータの6件目まで処理を行う

                for (var elem in entryJson[i]) { //無害化しておく
                  entryJson[i][elem] = escapeHtml(entryJson[i][elem]);
                }

                result += '<li class="st-Grids_Item">' +
                    '<div class="sw-BlockB">';

                //リンクを別タブで起動する
                result += '<a class="sw-BlockB_ImageArea" target=”_blank” rel="noopener noreferrer" href="' + entryJson[i]['event_link'] + '">' +
                    '<img src="' + entryJson[i]['event_img'] + '" alt="" class="sw-BlockB_Image">' +
                    '</a>';
                result += '<a class="sw-BlockB_Heading" target=”_blank” rel="noopener noreferrer" href="' + entryJson[i]['event_link'] + '">' +
                    '<span class="sw-BlockB_HeadingIcon" aria-hidden="true"></span>' +
                    entryJson[i]['title'] +
                    '</a></div></li>';
              }
              result += '</ul>';
            } else {  //1件もなかった場合何もしない
              }
            })
          .fail(function () {
            result = ''
          })
          .always(function () { //出力
            var eventsContentFooter = $('.js-companyDetailForSearch');
            eventsContentFooter.html(result);
          });
      }
    } catch (e) {
      console.error(e.message)
    } finally {
      result = '';
    }
  }
});
