$(function() {

  function stGlobalNavLink() {

    // URLからディレクトリだけを取得する
    function currentDirName(path) {
      return path.replace(/\\/g,'/').replace(/\/[^\/]*$/, '') + '/';;
    }

    var locationPathname = location.pathname;
    var url = '/' + locationPathname.split("/")[1] + '/';
    var isEnglish = false;

    // URLに以下のキーワードが入っているページは第3階層が対象になる。
    var path = [
      "spatial_services/convention",
      "support_activities/new_business_creation",
      "support_activities/business_support",
      "labplus" // ラボポータル用
    ];
    var hasThirdDir = false;
    var hasSecondDir = false; // ラボポータル用

    // `path`の値がURLに含まれている場合にtrueを返す。
    for(var i = 0; i < path.length; i++) {
      if(locationPathname.match(path[i])) {
        hasThirdDir = true;
        hasSecondDir = true; // ラボポータル用
        break;
      }
    }

    // 日本語以外のディレクトリは1つ下の階層を対象とする。
    if(hasThirdDir) {
      url =  '/' + locationPathname.split("/")[1] + '/' + locationPathname.split("/")[2] + '/' + locationPathname.split("/")[3] + '/';
    } else if(url === '/english/') {
      url =  '/' + locationPathname.split("/")[1] + '/' + locationPathname.split("/")[2] + '/';
      isEnglish = true;
    }

    // "labplus"を含む場合は2つ下の階層を対象とする。(ラボポータル用)
    if(hasSecondDir) {
      url =  '/' + locationPathname.split("/")[1] + '/' + locationPathname.split("/")[2] + '/';
    }


    if($('.st-HeaderNav_Link').length) {
      var secondDir = '/' + locationPathname.split("/")[1] + '/' + locationPathname.split("/")[2] + '/';
      var isMunicipality = secondDir === '/sks/municipality/' ? true: false;

      $('.st-HeaderNav_Link').each(function() {
        // '/sks/municipality/' の場合は、「スタートアップ支援」と「プラットフォーム活動」にカレントを付与する。
        if(isMunicipality) {
          // コメントアウトを解除すると、「スタートアップ支援」にもカレントを付与する。
          // $('.st-HeaderNav_Link[href="/sks/"]').addClass('st-HeaderNav_Link-current');
          $('.st-HeaderNav_Link[href="/sangaku/"]').addClass('st-HeaderNav_Link-current');
          return;
        }

        if($(this).attr('href') === url || url.match(/search/) && $(this).attr('href').match(/search/)) { // &&以下ラボポータル用
          $(this).addClass('st-HeaderNav_Link-current');
        }
      });
    }
  }
  stGlobalNavLink();

}());
