$(function() {

  function setStLocalTitleB() {
    if(window.innerWidth >= 980) {
      $('.st-LocalTitleB').css('pointer-events', 'none');
    } else {
      $('.st-LocalTitleB').css('pointer-events', '');
    }
  }

  function stLocalTitle() {
    $('.st-LocalTitleB[aria-expanded]').on('click', function() {
      var $this = $(this);
      var $nav = $('#' + $this.attr('aria-controls'));
      var expanded = $this.attr('aria-expanded');

      if(expanded === 'false') {
        $this.attr('aria-expanded', 'true');
        $nav.slideDown(300).attr('aria-hidden', 'false');
      } else {
        $this.attr('aria-expanded', 'false');
        $nav.slideUp(300).attr('aria-hidden', 'true');
      }
    });
  }
  stLocalTitle();

  $(window).on('resize', _.throttle(function() {
    setStLocalTitleB();
  }, 300));


}());
