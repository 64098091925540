$(function() {

  function stLocalNav5Link() {
    var dir = location.pathname.split("/");
    var url = '/' + dir[1] + '/';
    if($('.st-LocalNav5_Link').length) {
      $('.st-LocalNav5_Link').each(function() {
        var href = $(this).attr('href').split("/");
        if('/' + href[1] + '/' == url ) {
          $(this).addClass('st-LocalNav5_Link-current');
        }
      });
    }
  }
  stLocalNav5Link();

  function setStLocalNav5() {
    if(window.innerWidth >= 980) {
      $('.st-LocalNav5_Title').css('pointer-events', 'none');
    } else {
      $('.st-LocalNav5_Title').css('pointer-events', '');
    }
  }

  function stLocalNav5() {
    $('.st-LocalNav5_Title[aria-expanded]').on('click', function() {
      var $this = $(this);
      var $nav = $('#' + $this.attr('aria-controls'));
      var expanded = $this.attr('aria-expanded');

      if(expanded === 'false') {
        $this.attr('aria-expanded', 'true');
        $nav.slideDown(300).attr('aria-hidden', 'false');
      } else {
        $this.attr('aria-expanded', 'false');
        $nav.slideUp(300).attr('aria-hidden', 'true');
      }
    });
  }
  stLocalNav5();

  $(window).on('resize', _.throttle(function() {
    setStLocalNav5();
  }, 300));


}());
